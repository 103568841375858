import { useState } from 'react'
import format from 'date-fns/format'
import {
	Flex,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Button,
	useColorModeValue,
	useColorMode,
} from '@chakra-ui/react'
import Calendar from 'react-calendar'
import TimePicker, { type TimePickerValue } from 'react-time-picker'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { BITCOIN_ORANGE, GENESIS_BLOCK_DATE } from '../../../constants'
import { ModalOverlay } from '../../shared'
import { useAppSelector } from '../../../hooks'

interface DatePickerModalProps {
	isOpen: boolean
	onClose: () => void
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	onSearchBlockByDate: (date: Date, time: TimePickerValue) => void
}

export const DatePickerModal = ({
	isOpen,
	onClose,
	onSearchBlockByDate,
}: DatePickerModalProps) => {
	const now = new Date()
	const nowTime = format(now, 'HH:mm')
	const navigate = useNavigate()
	const { userLocale } = useAppSelector((state) => state.settings)
	const [pickerDate, setPickerDate] = useState(now)
	const [pickerTime, setPickerTime] = useState<TimePickerValue>(nowTime)
	const [isValidPickerValue, setIsValidPickerValue] = useState(false)
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const { colorMode } = useColorMode()

	const interactionStyles = {
		color: BITCOIN_ORANGE,
		borderColor: BITCOIN_ORANGE,
	}

	const handleSubmitDate = () => {
		onSearchBlockByDate(pickerDate, pickerTime)
		onClose()
		setPickerDate(now)
		navigate(`/${userLocale}`)
	}

	const handleCloseDate = () => {
		onClose()
		navigate(`/${userLocale}`)
	}

	const handleDatePickerChange = (date: Date) => {
		if (date) {
			setIsValidPickerValue(true)
		} else {
			setIsValidPickerValue(false)
		}
		setPickerDate(date)
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			motionPreset="slideInBottom"
			size="sm"
		>
			<ModalOverlay />

			<ModalContent
				bg={bg}
				borderWidth={1}
				borderColor={color}
				color={color}
				borderRadius="lg"
			>
				<ModalBody mt={3}>
					<Flex
						className={`colormode-${colorMode}`}
						direction="column"
						gap={4}
					>
						<Calendar
							// @ts-ignore
							onChange={handleDatePickerChange}
							value={pickerDate}
							minDate={new Date(GENESIS_BLOCK_DATE)}
							defaultView="decade"
						/>
						<TimePicker
							onChange={(value) => setPickerTime(value)}
							value={pickerTime}
							disableClock={true}
							clearIcon={null}
						/>
					</Flex>
				</ModalBody>

				<ModalFooter gap={2} color={color}>
					<Button
						onClick={handleCloseDate}
						variant="outline"
						colorScheme="black"
						fontSize="sm"
						borderWidth={2}
						textTransform="uppercase"
						_hover={interactionStyles}
					>
						<FormattedMessage id="shared.cancel" />
					</Button>
					<Button
						onClick={handleSubmitDate}
						disabled={!isValidPickerValue}
						variant="outline"
						colorScheme="black"
						fontSize="sm"
						borderWidth={2}
						textTransform="uppercase"
						_hover={interactionStyles}
					>
						<FormattedMessage id="shared.go" />
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
