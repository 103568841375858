export const primaryDataFontSize = {
	base: '12px',
	xxs: '14px',
	sm: '15px',
	md: '16px',
	lg: '17px',
	xl: '18px',
	xxl: '21px',
	jumbo: '23px',
}

export const secondaryDataFontSize = {
	base: '11px',
	xxs: '13px',
	sm: '12px',
	md: '14px',
	lg: '15px',
	xxl: '21px',
	jumbo: '26px',
}

export const secondaryAltDataFontSize = {
	base: 'xs',
	md: '14px',
	lg: '15px',
	xxl: '21px',
	jumbo: '24px',
}

export const cornersDataFontSize = {
	base: '11px',
	xxs: 'sm',
	sm: '16px',
	md: '17px',
	lg: '21px',
	xl: '24px',
	xxl: '28px',
	jumbo: '34px',
}

export const confModeFontSize = {
	base: '16px',
	xxs: '18px',
	sm: '20px',
	md: '24px',
	lg: '27px',
	xl: '30px',
	xxl: '32px',
	jumbo: '36px',
}
