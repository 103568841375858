import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import settingsReducer from '../components/features/settings/settingsReducer'
import gmAlertReducer from '../components/features/calendar-alert/gmAlertReducer'
import calAlertReducer from '../components/features/calendar-alert/calAlertReducer'
import sponsorsReducer from '../components/features/conf-mode/sponsorsReducer'
import currenciesReducer from '../components/features/currency/currenciesReducer'

const reducers = combineReducers({
	calAlert: calAlertReducer,
	gmAlert: gmAlertReducer,
	settings: settingsReducer,
	currencies: currenciesReducer,
	confModeSponsors: sponsorsReducer,
})

const persistConfig = {
	key: 'tc',
	storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
