import { Link } from '@chakra-ui/react'

import { P } from './TCInfoAccordion'
import { useAppSelector } from '../../../hooks'
import { BITCOIN_ORANGE } from '../../../constants'

export const TranslatorAttribution = () => {
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const attributionStyle = {
		paddingTop: '8px',
		borderTopWidth: 1,
		borderTopColor: 'whiteAlpha.400',
		borderStyle: 'dashed',
	}

	return (
		<>

			{userLocale === 'ar' && (
				<P {...attributionStyle}>
					<>
						{'شكر خاص ل '}
						<Link
							href="https://x.com/SaniExp"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'@SaniExp'}
						</Link>
						{' وفريق '}
						<Link
							href="https://x.com/Bitcoin21_ar"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'@Bitcoin21_ar'}
						</Link>
						{' على ترجمة تقويم السلسلة الزمنية (Timechain Calendar) الى النسخة العربية.'}
					</>
				</P>
			)}

			{userLocale === 'ca' && (
				<P {...attributionStyle}>
					<>
						{'Gràcies a '}
						<Link
							href="https://x.com/bluelynx21"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'@bluelynx21'}
						</Link>
						{' per traduïr Timechain Calendar al Català.'}
					</>
				</P>
			)}


			{userLocale === 'de' && (
				<P {...attributionStyle}>
					<>
						{'Danke an '}
						<Link
							href="https://x.com/derHelper"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'@derHelper'}
						</Link>
						{' für die Hilfe bei der deutschen Übersetzung des Timechain Kalenders.'}
					</>
				</P>
			)}


			{userLocale === 'es' && (
				<P {...attributionStyle}>
					<>
						{'Gracias a '}
						<Link
							href="https://x.com/JaimeWGarcia"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Jaime García'}
						</Link>
						{' y '}
						<Link
							href="https://x.com/jairunet"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'@jairunet'}
						</Link>
						{' por ayudar a traducir el Calendario Timechain al castellano.'}
					</>
				</P>
			)}

			{userLocale === 'fr' && (
				<P {...attributionStyle}>
					<>
						{'Merci à '}
						<Link
							href="https://x.com/ghostofmellebtc"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Aude'}
						</Link>
						{' d\'avoir contribué à la traduction du Calendrier de la chaine temporelle en français.'}
					</>
				</P>
			)}

			{userLocale === 'gr' && (
				<P {...attributionStyle}>
					<>
						{'Ευχαριστούμε την '}
						<Link
							href="https://x.com/MiaThalassa"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Nina_hodl'}
						</Link>
						{' για την μετάφραση του Timechain Calendar στα Ελληνικά.'}
					</>
				</P>
			)}

			{userLocale === 'it' && (
				<P {...attributionStyle}>
					<>
						{'Grazie a '}
						<Link
							href="https://x.com/poolcinella_"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'@poolcinella_'}
						</Link>
						{' per la traduzione del Calendario Timechain in Italiano.'}
					</>
				</P>
			)}

			{userLocale === 'nl' && (
				<P {...attributionStyle}>
					<>
						{'Onze dank gaat uit naar '}
						<Link
							href="https://x.com/OutsideTheSyst2"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Electric Samuray'}
						</Link>
						{' voor het vertalen van de Timechain Calendar naar het Nederlands.'}
					</>
				</P>
			)}

			{userLocale === 'pl' && (
				<P {...attributionStyle}>
					<>
						{'Podziękowania dla '}
						<Link
							href="https://x.com/GracjanPietras"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Gracjana Pietrasa'}
						</Link>
						{' za pomoc w opracowaniu wersji polskiej.'}
						{' Dodatkowa pomoc w testowaniu od '}
						<Link
							href="https://x.com/maciejsoltysiak"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'JesterHodl'}
						</Link>
						{' i Aleksander Pietras.'}
					</>
				</P>
			)}

			{userLocale === 'pt' && (
				<P {...attributionStyle}>
					<>
						{'Obrigado a '}
						<Link
							href="https://x.com/Hugo_Quinteiro"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'@Hugo_Quinteiro'}
						</Link>
						{' e '}
						<Link
							href="https://x.com/kavanhak"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'@kavanhak'}
						</Link>
						{' por ajudar a traduzir o Calendário Timechain para português.'}
					</>
				</P>
			)}

			{userLocale === 'ru' && (
				<P {...attributionStyle}>
					<>
						{'Спасибо '}
						<Link
							href="https://nostree.me/tony"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Тони'}
						</Link>
						{' за перевод Таймчейн-календаря на русский.'}
					</>
				</P>
			)}

			{userLocale === 'sv' && (
				<P {...attributionStyle}>
					<>
						{'Tack till '}
						<Link
							href="https://x.com/bjorntisjo"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Björn'}
						</Link>
						{' för hjälp med att översätta Timechain Kalendar till svenska. '}
						<Link
							href="https://bitcoinhandboken.se/translation-of-bitcoin-texts-into-swedish"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Bitcoinhandboken och översättningar av bitcointexter.'}
						</Link>
					</>
				</P>
			)}

			{userLocale === 'tr' && (
				<P {...attributionStyle}>
					<>
						{'Sayın '}
						<Link
							href="https://x.com/imabearhunter"
							color={BITCOIN_ORANGE}
							isExternal
						>
							{'Oğuz'}
						</Link>
						{' Atasert\'e Timechain Takvimi\'ni tercüme etme yardımlarından dolayı çok teşekkür ederiz.'}
					</>
				</P>
			)}
		</>
	)
}
