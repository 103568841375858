export const MEMPOOL_SPACE_API_V1_URL = 'https://mempool.space/api/v1'
export const COINCAP_WEBSOCKET_URL = 'wss://ws.coincap.io/prices?assets=bitcoin'
export const BLOCKCHAIN_INFO_BASE_URL = 'https://blockchain.info'
export const CORS_PROXY_BASE_URL = 'https://corsproxy.io'
export const QRSNAP_BASE_URL = 'https://tcc.qrsnap.io'
export const TCADMIN_API_URL = 'https://admin.timechaincalendar.com/wp-json/wp/v2'
export const BITNODES_API_URL = 'https://bitnodes.io/api/v1/snapshots/'
export const TIMECHAININDEX_API_URL = 'https://timechainindex.com/api'

export const TIMECHAIN_CALENDAR_API_URL = 'https://api.timechaincalendar.com'
export const TIMECHAIN_CALENDAR_PRICE_API_URL = 'wss://exchange.timechaincalendar.com'
export const TIMECHAIN_CALENDAR_STATIC1_URL = 'https://static1.timechaincalendar.com'
export const TCD_PRICE_API_URL = 'wss://price.timechaindata.com/feed/v1'
