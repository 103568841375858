import {
	Flex,
	Box,
	Link,
	useColorMode,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import {
	circlesBreakpointState,
	confModeState,
	appInitializedState,
	showBlockRippleState,
} from '../../../state'
import { TCDonate, TCInfo, TCSettings } from '../../features'
import { getBreakpointValue } from '../../../utils'
import { blurInAnimation } from '../../../constants'

export const HeaderMenu = () => {
	const { colorMode } = useColorMode()
	const appInitialized = useRecoilValue(appInitializedState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const confMode = useRecoilValue(confModeState)
	const w = getBreakpointValue({ base: '26px', lg: '30px' }, circlesBreakpoint)
	const opacity = showBlockRipple ? 0.21 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'
	const bgImage = colorMode === 'light'
		? 'url(/TimechainCalendarIconLight.png)'
		: 'url(/icon-tc-512.png)'
	const isExchangePage = location.pathname.includes('exchange')
	const justify = isExchangePage ? 'center' : 'space-between'

	return (
		<Flex
			w="100%"
			align="center"
			justify={justify}
			gap={5}
		>
			{!confMode && (
				<>
					<Link href="/">
						{appInitialized && (
							<Box 
								h={w}
								w={w}
								backgroundImage={bgImage}
								backgroundPosition="center center"
								backgroundSize="contain"
								backgroundRepeat="no-repeat"
								boxShadow="none"
							/>
						)}
					</Link>
					
					{appInitialized && !isExchangePage && (
						<Flex
							gap={2}
							animation={blurInAnimation}
							filter={filter}
							opacity={opacity}
						>
							<TCSettings />
							
							<TCInfo />

							<TCDonate />
						</Flex>
					)}
				</>
			)}
		</Flex>
	)
}
