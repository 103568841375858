import {
	type FC,
	type PropsWithChildren,
} from 'react'
import {
	Flex,
	Box,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { getBreakpointValue } from '../../../utils'

type PriceProps = {
	both: boolean
	price?: number | string
}
type PriceWrapProps = PriceProps & PropsWithChildren

const Corners: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Flex
			w="100%"
			justify="space-between"
			pos="relative"
			zIndex={1016}
		>
			{children}
		</Flex>
	)
}

export const TopCorners: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Box pos="absolute" top={0} left={0} right={0}>
			<Corners>
				{children}
			</Corners>
		</Box>
	)
}

export const BottomCorners: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Box pos="absolute" bottom={0} left={0} right={0}>
			<Corners>
				{children}
			</Corners>
		</Box>
	)
}

export const PriceValue: FC<PriceWrapProps> = ({ children, both }) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const numbersStyle = both
		? getBreakpointValue({
			base: 'sm',
			xxs: 'md',
			xs: '22px',
			sm: '27px',
			md: '30px',
			lg: '34px',
			xxl: '40px',
			xxxl: '44px',
			jumbo: '56px',
		}, circlesBreakpoint)
		: getBreakpointValue({
			base: 'md',
			xxxs: 'xl',
			xxs: '2xl',
			sm: '30px',
			md: '33px',
			lg: '36px',
			xl: '44px',
			xxl: '58px',
			jumbo: '62px',
		}, circlesBreakpoint)

	return (
		<Text
			fontSize={numbersStyle}
			fontWeight="semibold"
			lineHeight="none"
		>
			{children}
		</Text>
	)
}

export const PriceWrap = ({
	children,
	both,
	price,
	...rest
}: PriceWrapProps) => {
	return (
		<Flex
			direction="column"
			align="flex-end"
			{...rest}
		>
			<Flex direction="column">
				<Flex
					align="flex-end"
					direction="column"
				>
					<PriceValue both={both}>
						{price}
					</PriceValue>
					{children}
				</Flex>
			</Flex>
		</Flex>
	)
}

export const Price: FC<PriceWrapProps> = ({ children }) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const color2 = useColorModeValue('rgbe(0,0,0,0.7', 'whiteAlpha.700')

	const captionFontSize = getBreakpointValue({ base: '9px', md: '10px', lg: '11px', xl: '13px', jumbo: '14px' }, circlesBreakpoint)
	const lineHeight = getBreakpointValue({ base: 'normal', md: 'none' }, circlesBreakpoint)

	return (
		<Flex
			color={color2}
			fontSize={captionFontSize}
			fontWeight="semibold"
			lineHeight={lineHeight}
		>
			{children}
		</Flex>
	)
}
