// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRandomItem = (items: any[]) => {
	return items[Math.floor(Math.random() * items.length)]
}

export const extractValuesByKey = <T extends object, K extends keyof T>(objects: Array<T>, key: K): Array<T[K]> => {
	const extractedValues: Array<T[K]> = []

	objects.forEach((obj: T) => {
		if (key in obj) {
			extractedValues.push(obj[key])
		} else {
			throw new Error(`Key '${String(key)}' not found in one or more objects.`)
		}
	})

	return extractedValues
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupByProperty = (arr: any, prop: string) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const grouped = arr.reduce((acc: any, obj: any) => {
		const key = obj[prop]
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const group = acc.find((item: any) => item.key === key)
		if (group) {
			group.values.push(obj)
		} else {
			acc.push({ key, values: [obj] })
		}
		return acc
	}, [])

	return grouped
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const collectUniqueValues = (objects: any[], property: string): any[] => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const arrayIncludesValue = (arr: any[], value: any) => arr.includes(value)
	
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const mergeUnique = (arr1: any[], arr2: any[]) => {
		arr2.forEach((item) => {
			if (!arrayIncludesValue(arr1, item)) {
				arr1.push(item)
			}
		})
		return arr1
	}
	
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const collectedValues: any[] = []

	objects.forEach((obj) => {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(property)) {
			if (Array.isArray(obj[property])) {
				mergeUnique(collectedValues, obj[property])
			} else {
				throw new Error(`Property "${property}" is not an array.`)
			}
		} else {
			throw new Error(`Property "${property}" does not exist on some objects.`)
		}
	})
  
	return collectedValues
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortByProperty = <T>(arr: T[], prop: keyof T, desc = false): T[] => {
	const sorted = [...arr].sort((a, b) => {
		const aValue = a[prop]
		const bValue = b[prop]
		const aString = String(aValue).toLowerCase()
		const bString = String(bValue).toLowerCase()

		if (typeof aValue === 'number' && typeof bValue === 'number') {
			return desc ? bValue - aValue : aValue - bValue
		}
		if (aString < bString) return desc ? 1 : -1
		if (aString > bString) return desc ? -1 : 1
		return 0
	})
  
	return sorted
} 

export const filterArrayBySecondArrayProperty = <T, U, K1 extends keyof T, K2 extends keyof U>(
	arr1: T[],
	prop1: K1,
	arr2: U[],
	prop2: K2
): T[] => {
	return arr1.filter(item1 => 
		arr2.some(item2 => 
			// @ts-ignore
			// eslint-disable-next-line 
			item1[prop1] === item2[prop2]
		)
	)
}
