import React from 'react'
import ReactDOM from 'react-dom/client'
import { ColorModeScript } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { RecoilRoot } from 'recoil'

import { App } from './App'
import store from './store'
// @ts-ignore
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './index.css'
import { theme } from './theme'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const persistor = persistStore(store)
const rootElement = document.getElementById('tcRoot')
if (!rootElement) {
	throw new Error('No root element found')
}
const root = ReactDOM.createRoot(rootElement)

root.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<BrowserRouter>
				<RecoilRoot>
					<ColorModeScript initialColorMode={theme.initialColorMode} />
					<App />
				</RecoilRoot>
			</BrowserRouter>
		</PersistGate>
	</Provider>
)

serviceWorkerRegistration.register()
