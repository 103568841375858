import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import { ACTIVE_USER_LOCALES, type UserLocale } from '../../lang/messages'

interface TCHeadProps {
	locale: UserLocale
}

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		btcpay: any | undefined
	}
}

export const TCHead = ({ locale }: TCHeadProps) => {
	const intl = useIntl()
	const domain = 'timechaincalendar.com'
	const baseUrl = `https://${domain}`
	const siteTitle = intl.formatMessage({ id: 'app.title.timechain_calendar' })
	const siteDescription = intl.formatMessage({ id: 'app.description' })
	const siteUrl = `${baseUrl}/${locale}`
	const selfHosted = process.env.REACT_APP_SELF_HOSTED === 'true'
	const productionMode = process.env.NODE_ENV === 'production'

	return (
		<Helmet>
			<html lang={locale} />
			<title>{siteTitle.toUpperCase()}</title>
			<meta name="description" content={siteDescription} />
			<meta name="application-name" content={siteTitle} />
			<meta name="apple-mobile-web-app-title" content={siteTitle} />
			<meta name="mobile-web-app-title" content={siteTitle} />

			<meta property="og:url" content={siteUrl} />
			<meta property="og:locale" content={locale} />
			<meta property="og:description" content={siteDescription} />
			<meta property="twitter:url" content={siteUrl} />
			<meta name="twitter:description" content={siteDescription} />

			{/* HREF LANG TAGS */}
			{ACTIVE_USER_LOCALES.map(lang => {
				if (lang === locale) return null
				return (
					<link
						key={lang}
						rel="alternate"
						href={`${baseUrl}/${lang}`}
						hrefLang={lang}
					/>
				)
			})}

			{!window.btcpay && (
				<script src="https://btcpay722165.lndyn.com/modal/btcpay.js" />
			)}

			{!selfHosted && productionMode && (
				<script src="https://cdn.usefathom.com/script.js" data-site="FHHCIDIQ" defer />
			)}
		</Helmet>
	)
}
