import { useEffect, useState } from 'react'
import {
	Flex,
	Box,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	IconButton,
	chakra,
	shouldForwardProp,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import {
	ChevronRightIcon,
	ChevronLeftIcon,
} from '@chakra-ui/icons'
import { motion, isValidMotionProp } from 'framer-motion'
import { useRecoilValue } from 'recoil'

import {
	blockState,
	staticModeState,
	circlesBreakpointState,
	scrubBlockState,
	showBlockRippleState,
} from '../../../state'
import { BlockScrubLabel } from './BlockScrub.components'
import { blurInAnimation, BITCOIN_ORANGE } from '../../../constants'
import { getBreakpointValue } from '../../../utils'
import { responsiveBlockScrubHeight } from './blockScrub.constants'
import { useAppSelector } from '../../../hooks'

interface BlockScrubProps {
	// scrubBlock: Block
	// eslint-disable-next-line no-unused-vars
	onScrubChange: (value: number) => void
	onScrubChangeStart: () => void
	// eslint-disable-next-line no-unused-vars
	onScrubChangeEnd: (value: number) => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const BlockScrub = ({
	onScrubChange,
	onScrubChangeStart,
	onScrubChangeEnd,
}: BlockScrubProps) => {
	const { reducedMotion } = useAppSelector(({ settings }) => settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const blockHeight = block.height
	const scrubHeight = scrubBlock.height
	const [scrubValue, setScrubValue] = useState(blockHeight)
	
	const buttonHeight = getBreakpointValue(responsiveBlockScrubHeight, circlesBreakpoint)
	const sliderMarkHeight = getBreakpointValue({ base: '28px', lg: '38px' }, circlesBreakpoint)
	const sliderMt = getBreakpointValue({ base: '-13px', lg: '-18px' }, circlesBreakpoint)
	const sliderTrackH = getBreakpointValue({ base: '2px', lg: '3px' }, circlesBreakpoint)
	const responsiveGap = getBreakpointValue({ base: '6px', sm: 2, md: 3 }, circlesBreakpoint)
	const responsiveScrubLabelMt = getBreakpointValue({ base: '3.5%', sm: '2.5%', xxl: '2%' }, circlesBreakpoint)
	const [
		darkorange40,
		lightorange80,
		lightorange40,
		orange100,
	] = useToken('colors', [
		'darkorange40',
		'lightorange80',
		'lightorange40',
		'orange100'
	])
	const sliderThumbBorderColor = useColorModeValue([orange100, darkorange40], [orange100, lightorange40])
	const bgGradientInner = useColorModeValue('linear(to-t, white, white, white, #ddd, #bbb)', 'linear(to-t, #555, #333, black)')
	const bgGradientOuter = useColorModeValue('linear(to-b, white, white, white, #ddd, #bbb)', 'linear(to-b, #555, black, black)')
	const color = useColorModeValue('black', 'white')
	const color2 = useColorModeValue('white', 'black')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const trackGradient = useColorModeValue(`linear(to-r, ${lightorange80}, ${orange100})`, `linear(to-r, ${orange100}, ${orange100})`)
	const leftMarkColor = useColorModeValue(lightorange80, orange100)

	const prevDisabled = scrubValue === 0
	const nextDisabled = !staticMode
	const buttonHoverStyle = {
		color: BITCOIN_ORANGE,
		borderColor: BITCOIN_ORANGE,
	}
	const startMarkColor = staticMode ? color : colorAlt
	const endMarkColor = staticMode ? color : colorAlt
	const endMarkBorderColor = staticMode ? '#777' : BITCOIN_ORANGE
	const rightButtonHoverStyle = nextDisabled ? undefined : buttonHoverStyle
	const leftButtonsHoverStyle = prevDisabled ? undefined : buttonHoverStyle
	const handleTransitionDuration = staticMode ? 0.5 : 1
	const opacity = showBlockRipple ? 0.21 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'

	const thumbAnimate = reducedMotion
		? undefined
		: {
			borderColor: sliderThumbBorderColor,
		}
	const thumbTransition = reducedMotion
		? undefined
		: {
			duration: handleTransitionDuration,
			ease: 'linear',
			repeat: Infinity,
			repeatType: 'loop',
		}

	const handleScrubChange = (value: number) => {
		onScrubChange(value)
		setScrubValue(value)
	}
	const handleIncrementScrubBlock = () => {
		const newValue = scrubValue + 1
		if (newValue <= blockHeight) {
			onScrubChangeEnd(newValue)
			setScrubValue(newValue)
		}
	}
	const handleDecrementScrubBlock = () => {
		const newValue = scrubValue - 1
		if (newValue >= 0) {
			onScrubChangeEnd(newValue)
			setScrubValue(newValue)
		}
	}

	useEffect(() => {
		if (!staticMode) {
			setScrubValue(blockHeight)
		}
	}, [blockHeight, staticMode, setScrubValue])

	useEffect(() => {
		setScrubValue(scrubHeight)
	}, [scrubHeight])

	return (
		<Flex
			className="tc-block-scrub"
			flex="0 0 100%"
			pos="relative"
			zIndex={1100}
			justify="space-between"
			align="center"
			gap={responsiveGap}
			h={buttonHeight}
			filter={filter}
			opacity={opacity}
			transition="all 0.45s ease"
			animation={blurInAnimation}
		>
			<Flex gap={1}>
				<IconButton
					size="sm"
					maxW="36px"
					h={buttonHeight}
					onClick={() => handleDecrementScrubBlock()}
					disabled={prevDisabled}
					icon={<ChevronLeftIcon w={8} h={8} />}
					colorScheme={color}
					borderWidth="2px"
					borderColor={color}
					color={color}
					bg={color2}
					aria-label="Decrease"
					_active={leftButtonsHoverStyle}
					_hover={leftButtonsHoverStyle}
				/>
			</Flex>

			<Slider
				colorScheme="orange"
				min={0}
				max={blockHeight}
				value={scrubHeight}
				onChange={(value) => handleScrubChange(value)}
				onChangeStart={() => onScrubChangeStart()}
				onChangeEnd={(value) => onScrubChangeEnd(value)}
				aria-label="Scrub Blocks"
			>
				<SliderTrack
					h={sliderTrackH}
					borderRadius={0}
					bg="#777"
				>
					<SliderFilledTrack
						bgGradient={trackGradient}
						transition="all 0.45s ease"
					/>
				</SliderTrack>

				<SliderThumb
					w={buttonHeight}
					h={buttonHeight}
					borderRadius="50%"
					outline="none"
					boxShadow="none"
					borderWidth={0}
					_focus={{
						outline: 'none',
						boxShadow: 'none',
					}}
				>
					<MotionBox
						w={buttonHeight}
						h={buttonHeight}
						borderRadius="50%"
						borderWidth="3px"
						borderColor={BITCOIN_ORANGE}
						bgGradient={bgGradientOuter}
						animate={thumbAnimate}
						// @ts-ignore
						transition={thumbTransition}
					>
						<Box
							pos="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							m="auto"
							w="60%"
							h="60%"
							borderRadius="50%"
							bgGradient={bgGradientInner}
						/>
					</MotionBox>
				</SliderThumb>

				<Flex
					w="100%"
					justify="space-between"
					mt={sliderMt}
				>
					<SliderMark
						value={0}
						color={startMarkColor}
						textAlign="left"
						fontSize="xxs"
						fontWeight="bold"
						borderLeftWidth="2px"
						borderColor={leftMarkColor}
						h={sliderMarkHeight}
					/>

					<SliderMark
						display="flex"
						alignItems="center"
						justifyContent="center"
						value={scrubHeight / 2}
						textAlign="center"
						left="0px !important"
						right={0}
						h="24px"
						mt={responsiveScrubLabelMt}
					>
						<BlockScrubLabel staticMode={staticMode} scrubHeight={scrubHeight} />
					</SliderMark>

					<SliderMark
						value={blockHeight}
						color={endMarkColor}
						textAlign="right"
						fontSize="xxs"
						fontWeight="bold"
						left="auto !important"
						right={0}
						borderRightWidth="2px"
						borderColor={endMarkBorderColor}
						h={sliderMarkHeight}
					/>
				</Flex>
			</Slider>

			<Flex gap={1}>
				<IconButton
					size="sm"
					maxW="36px"
					h={buttonHeight}
					onClick={() => handleIncrementScrubBlock()}
					disabled={nextDisabled}
					icon={<ChevronRightIcon w={8} h={8} />}
					colorScheme={color}
					borderWidth="2px"
					borderColor={color}
					color={color}
					bg={color2}
					aria-label="Advance 1"
					_active={rightButtonHoverStyle}
					_hover={rightButtonHoverStyle}
				/>
			</Flex>
		</Flex>
	)
}
