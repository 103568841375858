import {
	useState,
	useEffect,
	useRef,
} from 'react'
import {
	Flex,
	Box,
	Text,
	Image,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	CloseButton,
	Button,
	Link,
	useDisclosure,
	ResponsiveValue,
	chakra,
	shouldForwardProp,
	useColorModeValue,
	Spinner,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { useRecoilState, useRecoilValue } from 'recoil'
import axios from 'axios'
import Slider from 'react-slick'

import { useAppSelector } from '../../../hooks'
import {
	scrubBlockState,
	circlesBreakpointState,
	landscapeOrientationState,
	artBlocksState,
	artistsState,
	blockEmbedState,
} from '../../../state'
import {
	BITCOIN_ORANGE,
	TIMECHAIN_CALENDAR_API_URL,
	TIMECHAIN_CALENDAR_STATIC1_URL,
} from '../../../constants'
import { rtlLocales } from '../../../lang/messages'
import { FormattedMessage } from 'react-intl'
import { getBreakpointValue } from '../../../utils'
import { VideoPlayer } from '../video'
import { ModalOverlay } from '../../shared'

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const BlockEmbeds = () => {
	const { userLocale } = useAppSelector((state) => state.settings)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const scrubBlock = useRecoilValue(scrubBlockState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const artBlocks = useRecoilValue(artBlocksState)
	const artists = useRecoilValue(artistsState)
	const embedRef = useRef(null)
	const [embedAttribution, setEmbedAttribution] = useState('')
	const [embedImageHeight, setEmbedImageHeight] = useState(0)
	const [embedImageWidth, setEmbedImageWidth] = useState(0)
	const [embedImageUrl, setEmbedImageUrl] = useState('')
	const [embedTitle, setEmbedTitle] = useState('')
	const [embedDescription, setEmbedDescription] = useState('')
	const [embedLink, setEmbedLink] = useState('')
	const [embedVideoSrc, setEmbedVideoSrc] = useState('')
	const [embedButtonLabel, setEmbedButtonLabel] = useState('')
	const [embedSlides, setEmbedSlides] = useState<string[]>([])
	const [activeSlide, setActiveSlide] = useState(1)
	const [modalBodyHeight, setModalBodyHeight] = useState(0)
	const [blockEmbed, setBlockEmbed] = useRecoilState(blockEmbedState)
	const sliderRef = useRef(null)
	const modalBodyRef = useRef<HTMLDivElement>(null)
	const slideMaxH = modalBodyHeight > 0 ? `${modalBodyHeight - 60}px` : undefined
	const prevDisabled = activeSlide === 1
	const nextDisabled = activeSlide === embedSlides.length
	
	const boxShadow = useColorModeValue('0 0 40px 12px rgba(0,0,0,0.15)', '0 0 40px 12px rgba(255,255,255,0.15)')
	const boxShadow1 = useColorModeValue('0 0 36px 10px rgba(0,0,0,0.2)', '0 0 36px 10px rgba(255,255,255,0.13)')
	const boxShadow2 = useColorModeValue('0 0 40px 15px rgba(0,0,0,0.6)', '0 0 40px 15px rgba(255,255,255,0.37)')
	const boxShadow3 = useColorModeValue('0 0 36px 1px rgba(0,0,0,0.21)', '0 0 36px 1px rgba(255,255,255,0.13)')
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	
	const embedDetailsDirection = landscapeOrientation
		? getBreakpointValue({ base: 'column', md: 'row' }, circlesBreakpoint) as ResponsiveValue<'column' | 'row'>
		: 'column'
	const embedDetailsAlign = landscapeOrientation
		? getBreakpointValue({ base: 'flex-start', md: 'center' }, circlesBreakpoint) as ResponsiveValue<'flex-start' | 'center'>
		: 'flex-start'
	const embedDescriptionTextAlign = getBreakpointValue({ base: 'center', md: 'left' }, circlesBreakpoint) as ResponsiveValue<'left' | 'center'>
	const embedDescriptionTextMb = getBreakpointValue({ base: 4, md: 0 }, circlesBreakpoint)
	const embedDetailsGap = landscapeOrientation
		? getBreakpointValue({ base: 2, md: 6 }, circlesBreakpoint)
		: getBreakpointValue({ base: 2, xl: 6 }, circlesBreakpoint)
	const titleAlign = landscapeOrientation ? undefined : 'center'

	const descriptionMaxH = landscapeOrientation ? '100px' : '180px'
	const buttonsWidth = landscapeOrientation ? 'auto' : '100%'
	const buttonsJustify = landscapeOrientation ? undefined : 'flex-end'
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined

	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
		textDecoration: 'none',
	}

	// FIND EMBED ON THIS BLOCK
	const artist = artists.find((x) => x.name === embedAttribution)
	const artistPath = artist ? artist.path : ''
	const blockEmbedLink = artist ? (embedLink || artist.link) : ''
	const showBlockEmbed = artist
		&& artistPath
		&& embedAttribution
		&& embedImageHeight
		&& embedImageWidth
		&& embedImageUrl
		&& embedTitle
	const embedLoading = blockEmbed && !showBlockEmbed

	// // DIMENSIONS FOR UI
	const thumbBoxSize = 300
	const thumbBoxSizePx = `${thumbBoxSize}px`
	// image orientation & ratios
	const imageLandscape = embedImageWidth > embedImageHeight
	const imageAspectRatio = embedImageWidth / embedImageHeight
	const imageAspectRatioInverse = embedImageHeight / embedImageWidth
	// thumb sizes
	const maxW = `${thumbBoxSize * imageAspectRatio}px`
	const maxH = imageLandscape ? `${thumbBoxSize * imageAspectRatioInverse}px` : undefined
	// full image src
	const embedFullImageUrl = `${TIMECHAIN_CALENDAR_STATIC1_URL}/${artistPath}/${embedImageUrl}`

	const handleSliderPrev = () => {
		if (sliderRef.current) {
			// @ts-ignore
			sliderRef.current.slickPrev()
		}
	}

	const handleSliderNext = () => {
		if (sliderRef.current) {
			// @ts-ignore
			sliderRef.current.slickNext()
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSetActiveSlide = (current: any) => {
		setActiveSlide(current + 1)
	}

	useEffect(() => {
		setTimeout(() => {
			if (isOpen && modalBodyRef.current) {
				// @ts-ignore
				setModalBodyHeight(modalBodyRef.current.clientHeight)
			}
		}, 50)
	}, [isOpen, embedSlides])

	useEffect(() => {
		const newBlockEmbed = artBlocks.find((embed) => embed.b === scrubBlock.height)

		if (newBlockEmbed) {
			setBlockEmbed(newBlockEmbed)
		}

		return () => setBlockEmbed(null)
	}, [scrubBlock])

	useEffect(() => {
		const requestUrl = `${TIMECHAIN_CALENDAR_API_URL}/gallery-item?block=${scrubBlock.height}`

		const fetchGalleryItem = () => {
			axios.get(requestUrl)
				.then((res) => {
					console.info(`[gallery] fetch embed ${scrubBlock.height} success`)
					const { 
						attribution,
						imageHeight,
						imageUrl,
						imageWidth,
						title,
						// optional embed values
						description,
						link,
						videoSrc,
						buttonLabel,
						slides,
					} = res.data

					setEmbedAttribution(attribution)
					setEmbedImageHeight(imageHeight)
					setEmbedImageWidth(imageWidth)
					setEmbedImageUrl(imageUrl)
					setEmbedTitle(title)
					if (description) {
						setEmbedDescription(description)
					}
					if (link) {
						setEmbedLink(link)
					}
					if (videoSrc) {
						setEmbedVideoSrc(videoSrc)
					}
					if (buttonLabel) {
						setEmbedButtonLabel(buttonLabel)
					}
					if (slides) {
						setEmbedSlides(slides)
					}
				})
				.catch(error => {
					console.error('[gallery] fetch embed error:', error)
				})
			
			return () => {
				setEmbedAttribution('')
				setEmbedImageHeight(0)
				setEmbedImageWidth(0)
				setEmbedImageUrl('')
				setEmbedTitle('')
				setEmbedDescription('')
				setEmbedLink('')
				setEmbedVideoSrc('')
				setEmbedButtonLabel('')
			}
		}
		
		if (blockEmbed) {
			fetchGalleryItem()
		}
	}, [blockEmbed])

	if (!blockEmbed) {
		return null
	}

	return (
		<Flex
			className="tc-block-embed-wrap"
			w={thumbBoxSizePx}
			h={thumbBoxSizePx}
			maxH={maxH}
			justify="center"
			align="center"
			transition="all 0.45s ease"
		>
			{embedLoading && (
				<Spinner color={color} />
			)}

			{!embedLoading && (
				<MotionBox
					ref={embedRef}
					pos="relative"
					role="group"
					onClick={onOpen}
					w="100%"
					maxW={maxW}
					h="auto"
					overflow="hidden"
					cursor="pointer"
					borderRadius="md"
					boxShadow={boxShadow}
					animate={{
						boxShadow: [
							boxShadow1,
							boxShadow2,
							boxShadow3,
						],
					}}
					// @ts-ignore
					transition={{
						duration: 2.1,
						ease: 'linear',
						repeat: Infinity,
						repeatType: 'loop',
					}}
				>
					{embedVideoSrc && (
						<Box
							pos="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							m="auto"
							w="60px"
							h="60px"
							borderWidth={2}
							borderColor="white"
							borderRadius="50%"
							bg="rgba(0,0,0,0.5)"
							transition="all 0.45s ease"
							_groupHover={{
								borderColor: BITCOIN_ORANGE,
							}}
						>
							<Box
								pos="absolute"
								top={0}
								left={0}
								right={0}
								bottom={0}
								m="auto"
								w={0}
								h={0}
								borderTop="10px solid transparent"
								borderBottom="10px solid transparent"
								borderLeft="18px solid white"
								transition="all 0.45s ease"
								_groupHover={{
									borderLeftColor: BITCOIN_ORANGE,
								}}
							/>
						</Box>
					)}
					<Image
						src={embedFullImageUrl}
						alt={embedTitle}
						objectFit="cover"
					/>
				</MotionBox>
			)}

			<Modal
				key="gallery"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="full"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
					dir={direction}
				>
					<ModalHeader>
						<Flex
							align="center"
							justify="space-between"
							gap={2}
							color={colorAlt}
						>
							<Text
								color={color}
								textTransform="uppercase"
								fontSize="sm"
							>
								{embedAttribution}
							</Text>

							<CloseButton
								onClick={onClose}
								_hover={interactionStyles}
								_active={interactionStyles}
							/>
						</Flex>
					</ModalHeader>

					<ModalBody
						pos="relative"
						ref={modalBodyRef}
					>
						<Flex
							pos="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							direction="column"
							justify="center"
							align="center"
						>
							{embedVideoSrc && (
								<VideoPlayer
									videoSrc={embedVideoSrc}
									title={embedTitle}
								/>
							)}

							{!embedVideoSrc && embedSlides.length > 0 && (
								<Flex
									direction="column"
									w="100%"
									p={5}
									gap={4}
								>
									<Flex
										w="100%"
										justify="center"
										gap={4}
									>
										<Button
											onClick={handleSliderPrev}
											variant="outline"
											colorScheme="black"
											fontSize="sm"
											borderWidth={2}
											textTransform="uppercase"
											_active={prevDisabled ? undefined : interactionStyles}
											_hover={prevDisabled ? undefined : interactionStyles}
											cursor={prevDisabled ? 'default' : 'pointer'}
											disabled={prevDisabled}
											opacity={prevDisabled ? 0.5 : 1}
										>
											{'< PREV'}
										</Button>

										<Button
											onClick={handleSliderNext}
											variant="outline"
											colorScheme="black"
											fontSize="sm"
											borderWidth={2}
											textTransform="uppercase"
											_active={nextDisabled ? undefined : interactionStyles}
											_hover={nextDisabled ? undefined : interactionStyles}
											cursor={nextDisabled ? 'default' : 'pointer'}
											disabled={nextDisabled}
											opacity={nextDisabled ? 0.5 : 1}
										>
											{'NEXT >'}
										</Button>
									</Flex>

									<Slider
										ref={sliderRef}
										beforeChange={current => handleSetActiveSlide(current)}
										pauseOnHover={true}
										autoplay={false}
										infinite={false}
										speed={650}
										slidesToShow={1}
										slidesToScroll={1}
										swipeToSlide={true}
										nextArrow={<></>}
										prevArrow={<></>}
									>
										{embedSlides.map((slide: string, index) => {
											const fullUrl = `${TIMECHAIN_CALENDAR_STATIC1_URL}/${artistPath}/${slide}`

											return (
												<Box
													key={index}
													w="100%"
													maxH="100%"
												>
													<Flex
														w="100%"
														justify="center"
													>
														<Image
															src={fullUrl}
															w="auto"
															maxH={slideMaxH}
															h="auto"
														/>
													</Flex>
												</Box>
											)}
										)}
									</Slider>
								</Flex>
							)}

							{!embedVideoSrc && embedSlides.length === 0 && (
								<Image
									src={embedFullImageUrl}
									alt={embedTitle}
									objectFit="contain"
									htmlWidth={embedImageWidth}
									htmlHeight={embedImageHeight}
									maxW="100%"
									maxH="100%"
								/>
							)}
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Flex
							className="embed-modal-footer"
							w="100%"
							direction={embedDetailsDirection}
							justify="space-between"
							align={embedDetailsAlign}
							gap={embedDetailsGap}
						>
							<Flex
								direction="column"
								justify="flex-start"
								gap={1}
								maxW="320px"
							>
								<Text
									color={color}
									fontWeight="bold"
									fontSize="md"
									overflowWrap="break-word"
									align={titleAlign}
								>
									{`"${embedTitle}"`}
								</Text>
							</Flex>

							<Text
								flex={1}
								textAlign={embedDescriptionTextAlign}
								fontSize="sm"
								mb={embedDescriptionTextMb}
								maxH={descriptionMaxH}
								overflowY="scroll"
							>
								{embedDescription}
							</Text>

							<Flex
								w={buttonsWidth}
								justify={buttonsJustify}
								gap={4}
							>
								<Button
									onClick={onClose}
									variant="outline"
									colorScheme="black"
									fontSize="sm"
									borderWidth={2}
									textTransform="uppercase"
									_active={interactionStyles}
									_hover={interactionStyles}
								>
									<FormattedMessage id="shared.close_button_label" />
								</Button>

								<Button
									as={Link}
									href={blockEmbedLink}
									variant="outline"
									colorScheme="black"
									fontSize="sm"
									borderWidth={2}
									textTransform="uppercase"
									_active={interactionStyles}
									_hover={interactionStyles}
									isExternal
								>
									{embedButtonLabel || (<FormattedMessage id="alerts.bitcoin_dates.learn_more" />)}
								</Button>
							</Flex>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	)
}
