import {
	useEffect,
	useState,
} from 'react'
import {
	useRecoilValue,
	useRecoilState,
	useSetRecoilState,
} from 'recoil'
import {
	useParams,
} from 'react-router-dom'
import { Flex, Text } from '@chakra-ui/react'

import {
	appInitializedState,
	appRenderedState,
	blockState,
	scrubBlockState,
	connectionState,
	contentHeightState,
	circlesWrapWidthState,
	landscapeOrientationState,
	circlesBreakpointState,
	priceState,
} from './state'
import {
	Loading,
} from './components/features/calendar'
import {
	useAppSelector,
	useAppDispatch,
} from './hooks'
import {
	MIN_LANDSCAPE_CIRCLE_HEIGHT,
	TCD_PRICE_API_URL,
} from './constants'
import { setUserLocale } from './components/features/settings/settingsReducer'
import { ACTIVE_USER_LOCALES, type UserLocale } from './lang/messages'
import './theme/datepicker.css'
import './theme/timepicker.css'
import {
	getBreakpointValue,
	getMoscowTime,
	getSupplyIssued,
	abbreviateNumber,
} from './utils'
import { SidebarSection } from './components/layout/sidebar/SidebarSection'

export type Connection = {
	readyState: number
}

const TCExchange = () => {
	const dispatch = useAppDispatch()

	// @ts-ignore
	const { userLocale } = useAppSelector(({ settings }) => settings)

	const userLocalePath = useParams<'userLocalePath'>().userLocalePath

	const connection = useRecoilValue(connectionState)
	const block = useRecoilValue(blockState)
	const setScrubBlock = useSetRecoilState(scrubBlockState)
	const [price, setPrice] = useRecoilState(priceState)
	const [moscowTime, setMoscowTime] = useState(0)
	const [reconnectCounter, setReconnectCounter] = useState<number | undefined>(0)

	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const [appRendered, setAppRendered] = useRecoilState(appRenderedState)
	const [appInitialized, setAppInitalized] = useRecoilState(appInitializedState)

	const circleWrapWidth = useRecoilValue(circlesWrapWidthState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const setContentHeight = useSetRecoilState(contentHeightState)

	const HEADER_FOOTER_HEIGHT = getBreakpointValue({ base: 30, sm: 40 }, circlesBreakpoint) as number
	const flex = landscapeOrientation ? '1 0 auto' : '0 0 auto'
	const interfaceLoadingOpacity = circleWrapWidth === 0 ? 0 : 1

	const supply = getSupplyIssued(block.height)

	const marketCap = supply
		? abbreviateNumber(Math.floor(supply.supplyIssued * price), 2)
		: ''

	const formattedPrice = price.toFixed(0)

	const priceSize = getBreakpointValue({
		base: '40px',
		xxxs: '50px',
		xxs: '65px',
		xs: '80px',
		sm: '100px',
		md: '120px',
		lg: '145px',
		xl: '175px',
		xxl: '200px',
		xxxl: '240px',
		jumbo: '280px',
	}, circlesBreakpoint)

	const moscowTimeSize = getBreakpointValue({
		base: '30px',
		xxxs: '40px',
		xxs: '44px',
		xs: '50px',
		sm: '70px',
		md: '90px',
		lg: '100px',
		xl: '110px',
		xxl: '120px',
		xxxl: '140px',
		jumbo: '150px',
	}, circlesBreakpoint)

	const marketCapSize = getBreakpointValue({
		base: '20px',
		xxxs: '24px',
		xxs: '30px',
		xs: '35px',
		sm: '45px',
		md: '50px',
		lg: '60px',
		xl: '70px',
		xxl: '80px',
		xxxl: '90px',
		jumbo: '100px',
	}, circlesBreakpoint)

	// Document Title
	useEffect(() => {
		if (formattedPrice) {
			document.title = `BTC: ${formattedPrice}`
		}
	}, [formattedPrice])
	
	useEffect(() => {
		const headerAndFooter = HEADER_FOOTER_HEIGHT * 2
		const standardHeight = (window.innerHeight - headerAndFooter)
		const MIN_CONTENT_HEIGHT = MIN_LANDSCAPE_CIRCLE_HEIGHT + 30
		const contentHeight = landscapeOrientation
			? standardHeight < MIN_CONTENT_HEIGHT
				? MIN_CONTENT_HEIGHT
				: standardHeight
			: standardHeight

		setContentHeight(contentHeight)
	}, [circleWrapWidth, landscapeOrientation])

	// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //
	// INITIALIZATION EFFECTS /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //
	// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //

	// 1) Render flag set to true on load
	useEffect(() => {
		setAppRendered(true)
	}, [])

	// 2) If no block path on load initialize as normal
	useEffect(() => {
		const defaultLoadCondition = appRendered
			&& !appInitialized
			&& block.height !== 0

		if (defaultLoadCondition) {
			console.info('[app] init default')
			setScrubBlock(block)
			setAppInitalized(true)
		}
	}, [
		appRendered,
		appInitialized,
		block,
	])

	// If user locale in url path make sure to set the same in local storage
	useEffect(() => {
		let safeUserLocale
		const condition = appRendered
			&& !appInitialized
			&& userLocalePath
			&& userLocalePath !== userLocale

		if (condition) {
			if (userLocale === undefined || !userLocale) {
				console.info('[locale] undefined - set locale to EN')
				safeUserLocale = 'en'
			}
			if (userLocalePath && !ACTIVE_USER_LOCALES.includes(userLocalePath)) {
				console.info('[locale] not allowed - set locale to EN')
				safeUserLocale = 'en'
			}
			if (userLocalePath && ACTIVE_USER_LOCALES.includes(userLocalePath)) {
				safeUserLocale = userLocalePath
				console.info('[locale] path detected', userLocalePath)
			}

			dispatch(setUserLocale(safeUserLocale as UserLocale))
		}
	}, [
		appRendered,
		appInitialized,
		userLocalePath,
		userLocale,
	])

	useEffect(() => {
		const newMoscowTime = getMoscowTime(price)
		setMoscowTime(newMoscowTime)
	}, [price])

	useEffect(() => {
		console.log('Attempting WebSocket connection...')
		const ws = new WebSocket(TCD_PRICE_API_URL)
	
		ws.onopen = () => {
			console.log('Connected to WebSocket')
		}
	
		ws.onmessage = (event) => {
			const data = JSON.parse(event.data)
			if (data && data.btc) {
				setPrice(data.btc)
				console.log('Received message:', JSON.parse(event.data).btc)
			}
		}
	
		ws.onerror = (error) => {
			console.error('WebSocket error:', error)
		}
	
		ws.onclose = (event) => {
			console.log('WebSocket closed:', event.code, event.reason)
		}
	
		return () => {
			ws.close()
		}
	}, [])

	// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //
	// END INITIALIZATION EFFECTS /\/\/\/\/\/\/\/\/\/\/\/\/\/\ //
	// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ //

	if (!appInitialized || circleWrapWidth === 0) {
		return (
			<Loading />
		)
	}

	return (
		<>
			<Flex
				className="tc-exchange"
				opacity={interfaceLoadingOpacity}
				flex={flex}
				minW={circleWrapWidth}
				h="100%"
				direction="column"
				justify="center"
				align="center"
				// bgGradient="liner(to-top, black, orange)"
			>
				<SidebarSection title="USD / 1 BTC">
					<Flex w="100%" justify="center">
						<Text
							fontSize={priceSize}
							fontWeight="bold"
						>
							{formattedPrice}
						</Text>
					</Flex>
				</SidebarSection>
				

				<SidebarSection title="SATS / 1 USD">
					<Flex w="100%" justify="center">
						<Text
							fontSize={moscowTimeSize}
							fontWeight="bold"
						>
							{moscowTime}
						</Text>
					</Flex>
				</SidebarSection>

				<SidebarSection title="USD CAP">
					<Flex w="100%" justify="center">
						<Text
							fontSize={marketCapSize}
							fontWeight="bold"
						>
							{marketCap}
						</Text>
					</Flex>
				</SidebarSection>
			</Flex>
		</>
	)
}

export default TCExchange
