import {
	useEffect,
	useState,
	type ChangeEvent,
} from 'react'
import {
	Flex,
	Box,
	Text,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	Image,
	type ResponsiveValue,
	type FlexProps,
	Select,
	useColorModeValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { FormattedMessage, useIntl } from 'react-intl'
import LazyLoad from 'react-lazy-load'
import { useLocation, useNavigate } from 'react-router-dom'

import {
	blockState,
	circlesBreakpointState,
	landscapeOrientationState,
	artBlocksState,
	type ArtBlock,
	artistsState,
} from '../../../state'
import { useAppSelector } from '../../../hooks'
import {
	BITCOIN_ORANGE,
	CATEGORY_COLORS,
	type GallerySort,
	GALLERY_SORT_OPTIONS,
	TIMECHAIN_CALENDAR_STATIC1_URL,
} from '../../../constants'
import { TCGalleryButton } from './TCGalleryButton'
import { rtlLocales } from '../../../lang/messages'
import { GalleryIcon } from '../../svg'
import {
	getBreakpointValue,
	groupByProperty,
	sortByProperty,
} from '../../../utils'
import { Label, ModalOverlay } from '../../shared'

interface GalleryCategoryLabelProps {
	label: string
}

interface TCGalleryProps {
	// eslint-disable-next-line no-unused-vars
	onSearchBlock: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	onScrubChangeEnd: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	setScrubValue: (x: number) => void
}

interface GalleryFilterOptionProps {
	label: string
	value: string
}

type TCGalleryCombinedProps = TCGalleryProps & FlexProps

export const GalleryCategoryLabel = ({ label, ...rest }: GalleryCategoryLabelProps) => {
	const bgColor = CATEGORY_COLORS[label]

	return (
		<Box
			fontSize="xxs"
			fontWeight="bold"
			textTransform="uppercase"
			textAlign="center"
			color="white"
			borderRadius="20px"
			bgColor={bgColor}
			px={3}
			{...rest}
		>
			{label}
		</Box>
	)
}

const GalleryFilterOption = ({ label, value }: GalleryFilterOptionProps) => {
	return (
		<option value={value}>{label}</option>
	)
}

export const TCGallery = ({
	onSearchBlock,
	onScrubChangeEnd,
	setScrubValue,
	...rest
}: TCGalleryCombinedProps) => {
	const intl = useIntl()
	const navigate = useNavigate()
	const location = useLocation()
	const { userLocale } = useAppSelector((state) => state.settings)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const block = useRecoilValue(blockState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [sortedEmbeds, setSortedEmbeds] = useState<any[]>([])
	const [userGallerySort, setUserGallerySort] = useState<GallerySort>(GALLERY_SORT_OPTIONS.asc as GallerySort)
	const all = intl.formatMessage({ id: 'gallery.all'})
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [filteredEmbeds, setFilteredEmbeds] = useState<any[]>([])
	const [userGalleryFilter, setUserGalleryFilter] = useState(all)
	const [filteredArtist, setFilteredArtist] = useState('')
	
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const galleryThumbSize = getBreakpointValue({ base: 82, md: 98 }, circlesBreakpoint)
	const galleryListThumbSize = getBreakpointValue({ base: 60, md: 80 }, circlesBreakpoint)
	const blockFontSize = getBreakpointValue({ base: '14px', md: '15px' }, circlesBreakpoint)
	const galleryArtistHeaderDirection = getBreakpointValue({ base: 'column', md: 'row' }, circlesBreakpoint) as ResponsiveValue<'row' | 'column'>
	const artBlocks = useRecoilValue(artBlocksState)
	const artists = useRecoilValue(artistsState)

	const groupedEmbeds = groupByProperty(artBlocks, 'a')
	const sortedGroupedEmbeds = sortByProperty(groupedEmbeds, 'key')
	const groupedDescEmbeds = groupedEmbeds.reverse()
	const sortedGroupedDescEmbeds = sortByProperty(groupedDescEmbeds, 'key', true)

	const listedEmbeds = sortByProperty(artBlocks, 'b')
	const listedDescEmbeds = listedEmbeds.slice().reverse()

	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}
	const galleryThumbJustify = landscapeOrientation ? undefined : 'center'
	const galleryThumbSizeBorderString = `${Number(galleryThumbSize) + 2}px`
	const galleryListThumbSizeBorderString = `${Number(galleryListThumbSize) + 2}px`
	const isGroupedByArtist = userGallerySort === GALLERY_SORT_OPTIONS.asc
		|| userGallerySort === GALLERY_SORT_OPTIONS.desc
	const isBlockList = userGallerySort === GALLERY_SORT_OPTIONS.blockAsc
		|| userGallerySort === GALLERY_SORT_OPTIONS.blockDesc
	const isFiltered = filteredEmbeds.length !== 0
	const reversedFilteredEmbeds = filteredEmbeds.reverse()
	const sortedArtists = sortByProperty(artists, 'name')
	
	const filteredGroupedEmbeds = isFiltered && filteredEmbeds.length > 0
		? isBlockList
			? userGallerySort === GALLERY_SORT_OPTIONS.blockDesc
				? filteredEmbeds
				: reversedFilteredEmbeds
			: [{
				key: filteredArtist,
				values: filteredEmbeds,
			}]
		: []

	const displayEmbeds = isFiltered
		? filteredGroupedEmbeds
		: sortedEmbeds
	
	const handleOpenGallery = () => {
		navigate(`/${userLocale}/gallery`)
	}

	const handleCloseGallery = () => {
		onClose()
		onScrubChangeEnd(block.height)
		setScrubValue(block.height)
	}

	const handleGoToEmbedBlock = (embedBlock: number) => {
		if (embedBlock < block.height) onSearchBlock(embedBlock)
		onClose()
	}

	const handleGalleryFilter = (x: ChangeEvent<HTMLSelectElement>) => {
		const newFilter = x.target.value
		const newFilteredEmbeds = newFilter === all
			? []
			: artBlocks.filter((x) => x.a === newFilter)
		const DESC = userGallerySort === GALLERY_SORT_OPTIONS.blockDesc
		const sortedNewFilteredEmbeds = sortByProperty(newFilteredEmbeds, 'b', DESC)
		const newFilteredArtist = newFilter === all
			? ''
			: newFilter

		setUserGalleryFilter(newFilter)
		setFilteredArtist(newFilteredArtist)
		setFilteredEmbeds(sortedNewFilteredEmbeds)
	}

	const gallerySortChange = (sort: GallerySort, manual = false) => {
		if (manual) {
			setUserGallerySort(sort)
		}
		switch(sort) {
			case 'ASC':
				setSortedEmbeds(sortedGroupedEmbeds)
				break
			case 'DESC':
				setSortedEmbeds(sortedGroupedDescEmbeds)
				break
			case 'BLOCK_ASC':
				setSortedEmbeds(listedEmbeds)
				break
			case 'BLOCK_DESC':
				setSortedEmbeds(listedDescEmbeds)
				break
			default:
				setSortedEmbeds(sortedGroupedEmbeds)
		}
	}

	const handleGallerySort = (x: ChangeEvent<HTMLSelectElement>) => {
		const newSort = x.target.value as GallerySort
		gallerySortChange(newSort, true)
	}

	useEffect(() => {
		if (location.pathname.includes('/gallery')) {
			onOpen()
		}
	}, [location])

	useEffect(() => {
		gallerySortChange(userGallerySort)
	}, [isOpen])

	return (
		<>
			<TCGalleryButton
				onClick={handleOpenGallery}
				{...rest}
			/>

			<Modal
				key="gallery"
				isOpen={isOpen}
				onClose={handleCloseGallery}
				isCentered
				motionPreset="slideInBottom"
				size="3xl"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
					dir={direction}
				>
					<ModalHeader pb={0}>
						<Flex
							align="center"
							gap="1"
							color={colorAlt}
							mb={2}
						>
							<GalleryIcon width={20} />

							<Text
								fontSize="xs"
								textTransform="uppercase"
							>
								<FormattedMessage id="gallery.button_label" />
							</Text>
						</Flex>	

						<Flex justify="flex-end" gap={4}>
							<Flex
								align="center"
								gap={{ base: 2, md: 3 }}
								color={BITCOIN_ORANGE}
							>
								<Box pt={1}>
									<Label>
										<FormattedMessage id="gallery.filter" />:
									</Label>
								</Box>

								<Select
									size={{ base: 'xs', md: 'sm' }}
									onChange={handleGalleryFilter}
									value={userGalleryFilter}
									borderColor={colorAlt}
								>
									<GalleryFilterOption
										label={all}
										value={all}
									/>
									{sortedArtists.map((artist) => (
										<GalleryFilterOption
											key={artist.name}
											label={artist.name}
											value={artist.name}
										/>
									))}
								</Select>
							</Flex>

							<Flex
								align="center"
								gap={{ base: 2, md: 3 }}
								color={BITCOIN_ORANGE}
							>
								<Box pt={1}>
									<Label>
										<FormattedMessage id="gallery.sort" />:
									</Label>
								</Box>

								<Select
									size={{ base: 'xs', md: 'sm' }}
									onChange={handleGallerySort}
									value={userGallerySort}
									borderColor={colorAlt}
								>
									<GalleryFilterOption
										label={intl.formatMessage({ id: 'gallery.sort.artist_asc'})}
										value="ASC"
									/>
									<GalleryFilterOption
										label={intl.formatMessage({ id: 'gallery.sort.artist_desc'})}
										value="DESC"
									/>
									<GalleryFilterOption
										label={intl.formatMessage({ id: 'gallery.sort.block_asc'})}
										value="BLOCK_ASC"
									/>
									<GalleryFilterOption
										label={intl.formatMessage({ id: 'gallery.sort.block_desc'})}
										value="BLOCK_DESC"
									/>
								</Select>
							</Flex>
						</Flex>
					</ModalHeader>

					<ModalBody mt={4}>
						<Flex
							direction="column"
							maxH="550px"
							overflowY="auto"
						>
							{
								isOpen
								&& (isGroupedByArtist || (!isGroupedByArtist && !isBlockList))
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								&& displayEmbeds.map((group: any) => {
									const { key, values } = group
									const sortedValues = sortByProperty(values, 'b', true) as ArtBlock[]
									const artist = artists.find((x) => x.name === key)

									return (
										<Flex
											key={key}
											direction="column"
											borderWidth={1}
											borderColor="whiteAlpha.500"
											borderRadius="md"
											bg="whiteAlpha.100"
											p={4}
											pt={2}
											mb={10}
										>
											<Flex
												flexDirection={galleryArtistHeaderDirection}
												align="center"
												justify="space-between"
												mb={4}
											>
												<Text
													fontWeight="bold"
													my={2}
												>
													{key}
												</Text>

												<Flex gap={2}>
													{artist && artist.cats.map((cat) => {
														return (
															<GalleryCategoryLabel key={cat} label={cat} />
														)
													})}
												</Flex>
											</Flex>

											<Flex
												justify={galleryThumbJustify}
												wrap="wrap"
												gap={4}
											>
												{sortedValues.map((embed: ArtBlock, index: number) => {
													const {
														b: block,
														g,
														a,
													} = embed
													const artist = artists.find((x) => x.name === a)
													const gThumbUrl = artist
														? `${TIMECHAIN_CALENDAR_STATIC1_URL}/${artist.path}/${g}`
														: ''
													const title = `Block ${block} by ${a}`

													return (
														<Flex
															key={index}
															onClick={() => handleGoToEmbedBlock(block)}
															direction="column"
															role="group"
															cursor="pointer"
														>
															<Text
																fontSize={blockFontSize}
																lineHeight="none"
																mb={1}
																transition="all 0.45s ease"
																_groupHover={{
																	color: BITCOIN_ORANGE,
																}}
															>
																{block}
															</Text>

															<Box
																w={galleryThumbSizeBorderString}
																h={galleryThumbSizeBorderString}
																borderRadius="md"
																borderWidth={1}
																transition="all 0.45s ease"
																_groupHover={{
																	borderColor: BITCOIN_ORANGE,
																}}
															>
																<LazyLoad height={galleryThumbSize} offset={400}>
																	<Image
																		title={title}
																		flex={0}
																		boxSize={`${galleryThumbSize}px`}
																		htmlHeight={`${galleryThumbSize}px`}
																		htmlWidth={`${galleryThumbSize}px`}
																		src={gThumbUrl}
																		objectFit="cover"
																		borderRadius="md"
																	/>
																</LazyLoad>
															</Box>
														</Flex>
													)

												})}
											</Flex>
										</Flex>
									)
								})
							}

							{
								isOpen
								&& isBlockList
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								&& (
									<Flex direction="column">
										{displayEmbeds.map((embed: ArtBlock, index: number) => {
											const {
												b: block,
												g,
												a: attribution,
											} = embed
											const artist = artists.find((x) => x.name === attribution)
											const gThumbUrl = artist
												? `${TIMECHAIN_CALENDAR_STATIC1_URL}/${artist.path}/${g}`
												: ''
											const title = `Block ${block} by ${attribution}`

											return (
												<Flex
													key={index}
													align="center"
													onClick={() => handleGoToEmbedBlock(block)}
													role="group"
													cursor="pointer"
													gap={4}
													mb={2}
													p={2}
													borderWidth={1}
													borderColor="whiteAlpha.500"
													borderRadius="md"
													_hover={{
														borderColor: BITCOIN_ORANGE,
													}}
												>
													<Text
														w={{ base: '18%', md: '14%' }}
														fontSize={blockFontSize}
														lineHeight="none"
														transition="all 0.45s ease"
														_groupHover={{
															color: BITCOIN_ORANGE,
														}}
													>
														{block}
													</Text>
													
													<Box w={{ base: '20%', md: '15%' }}>
														<Box
															w={galleryListThumbSizeBorderString}
															h={galleryListThumbSizeBorderString}
															borderRadius="md"
															borderWidth={1}
															transition="all 0.45s ease"
															_groupHover={{
																borderColor: BITCOIN_ORANGE,
															}}
														>
															<LazyLoad height={galleryListThumbSize} offset={400}>
																<Image
																	title={title}
																	flex={0}
																	boxSize={`${galleryListThumbSize}px`}
																	htmlHeight={`${galleryListThumbSize}px`}
																	htmlWidth={`${galleryListThumbSize}px`}
																	src={gThumbUrl}
																	objectFit="cover"
																	borderRadius="md"
																/>
															</LazyLoad>
														</Box>
													</Box>

													<Text
														flexGrow={1}
														fontSize={blockFontSize}
														lineHeight="none"
														fontWeight="bold"
														transition="all 0.45s ease"
														_groupHover={{
															color: BITCOIN_ORANGE,
														}}
													>
														{attribution}
													</Text>
												</Flex>
											)
										})}
									</Flex>
								)
							}
						</Flex>
					</ModalBody>

					<ModalFooter mt={2}>
						<Flex
							w="100%"
							justify="space-between"
							align="center"
						>
							<Text
								color="whiteAlpha.500"
								fontSize="xs"
								textTransform="uppercase"
							>
								<FormattedMessage id="gallery.total" />: {artBlocks.length} <FormattedMessage id="gallery.items" />
							</Text>

							<Button
								onClick={handleCloseGallery}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<FormattedMessage id="shared.close_button_label" />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
