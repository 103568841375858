import type { ChangeEvent, FC, PropsWithChildren } from 'react'
import format from 'date-fns/format'
import useSound from 'use-sound'
import {
	Flex,
	Box,
	Stack,
	Switch,
	Radio,
	RadioGroup,
	Checkbox,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	useColorModeValue,
	useColorMode,
} from '@chakra-ui/react'
import { dateLocales } from '../../../lang/dateFnsLocales'
import { LanguageSetterButton } from '../i18n'
import { FormattedMessage, useIntl } from 'react-intl'

import {
	setMoscowTime,
	setCuckBucks,
	setShowFees,
	setFeesRTL,
	setShowMiningPool,
	setTimeFormat,
	setDateFormat,
	setAudioActive,
	setAudioSound,
	setMarketCap,
	setReducedMotion,
	setFullNewBlockRipple,
	setDisableHalvingPulse,
	setDisableDifficultyPulse,
} from './settingsReducer'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { Label } from '../../shared'
import {
	AUDIO_SOUNDS,
	TIME_FORMAT,
	DATE_FORMAT,
	FORMATS_CONFIG,
	BITCOIN_ORANGE,
	EXCHANGE_RATE_USDBTC_LABEL,
	EXCHANGE_RATE_SATSUSD_LABEL,
	type TimeFormat,
	type DateFormat,
	type SelectedAudioSound,
} from '../../../constants'
import { rtlLocales } from '../../../lang/messages'
import { CurrencySetter } from '../currency'
import { MoonIcon, SunIcon } from '../../svg'

const SettingsOptionLabel: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Label fontSize="xs" fontWeight="bold" lineHeight="shorter">
			{children}
		</Label>
	)
}

export const TCSettingsUserPreferences = () => {
	const intl = useIntl()
	const {
		showCuckBucks,
		showMoscowTime,
		showMarketCap,
		showFees,
		feesRTL,
		showMiningPool,
		reducedMotion,
		fullNewBlockRipple,
		disableHalvingPulse,
		disableDifficultyPulse,
		timeFormat,
		dateFormat,
		audioActive,
		audioSound,
		userLocale,
	} = useAppSelector(({ settings }) => settings)
	const { userCurrency } = useAppSelector(({ currencies }) => currencies)
	const align = rtlLocales.includes(userLocale) ? 'right' : 'left'
	const color = useColorModeValue('black', 'white')
	const iconColor = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const { colorMode, toggleColorMode } = useColorMode()

	const [playDropHeavy] = useSound('audio/drop-heavy.mp3', {
		playbackRate: 0.675,
		volume: 0.15,
	})
	const [playDropCave] = useSound('audio/drop-cave.mp3', {
		volume: 1,
	})
	const [playWhoosh] = useSound('audio/whoosh.mp3', {
		volume: 0.75,
	})
	const [playGong] = useSound('audio/gong.mp3')
	const [playDoorbell] = useSound('audio/door-bell.mp3', {
		volume: 0.45,
	})
	const [playBell] = useSound('audio/bell-clean.mp3')

	const dispatch = useAppDispatch()
	const date = new Date()
	const AUDIO_STATUS = audioActive
		? intl.formatMessage({ id: 'settings.sounds.on' })
		: intl.formatMessage({ id: 'settings.sounds.off' })

	const handlePlaySound = (sound: SelectedAudioSound) => {
		switch (sound) {
			case AUDIO_SOUNDS.drop:
				playDropHeavy()
				playDropCave()
				return
			case AUDIO_SOUNDS.whoosh:
				setTimeout(() => playWhoosh(), 250)
				return
			case AUDIO_SOUNDS.gong:
				setTimeout(() => playGong(), 290)
				return
			case AUDIO_SOUNDS.bell:
				setTimeout(() => playBell(), 230)
				return
			case AUDIO_SOUNDS.doorbell:
				setTimeout(() => playDoorbell(), 320)
				return
			default:
				return
		}
	}

	const handleShowMoscowTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setMoscowTime(event.target?.checked))
	}
	const handleShowCuckBucksChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setCuckBucks(event.target?.checked))
	}
	const handleShowMarketCapChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setMarketCap(event.target?.checked))
	}
	const handleShowFeesChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setShowFees(event.target?.checked))
	}
	const handleFeesRTLChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setFeesRTL(event.target?.checked))
	}
	const handleShowMiningPoolChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setShowMiningPool(event.target?.checked))
	}
	const handleTimeFormatChange = (newValue: TimeFormat) => {
		dispatch(setTimeFormat(newValue))
	}
	const handleDateFormatChange = (newValue: DateFormat) => {
		dispatch(setDateFormat(newValue))
	}
	const handleAudioActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setAudioActive(event.target?.checked))
		if (event.target?.checked) {
			handlePlaySound(audioSound)
		}
	}
	const handleAudioSoundChange = (newValue: SelectedAudioSound) => {
		dispatch(setAudioSound(newValue))
		handlePlaySound(newValue)
	}
	const handleToggleAnimations = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setReducedMotion(!event.target?.checked))
	}
	const handleNewBlockRippleChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setFullNewBlockRipple(event.target?.checked))
	}
	const handleHalvingPulseChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setDisableHalvingPulse(event.target?.checked))
	}
	const handleDifficultyPulseChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setDisableDifficultyPulse(event.target?.checked))
	}

	const SettingsTab: FC<PropsWithChildren> = ({ children }) => {
		return (
			<Tab
				fontSize="11px"
				textTransform="uppercase"
				fontWeight="bold"
				px="10px"
				_active={{
					backgroundColor: 'transparent',
				}}
				_hover={{
					color: BITCOIN_ORANGE,
				}}
			>
				{children}
			</Tab>
		)
	}

	return (
		<Flex
			w="100%"
			direction="column"
			minH="280px"
		>
			<Tabs colorScheme="orange" color={color}>
				<TabList>
					<SettingsTab>
						<FormattedMessage id="settings.tab_label.display" />
					</SettingsTab>

					<SettingsTab>
						<FormattedMessage id="settings.tab_label.locale" />
					</SettingsTab>

					<SettingsTab>
						<FormattedMessage id="settings.tab_label.sounds" />
					</SettingsTab>
				</TabList>

				<TabPanels pt={2}>
					<TabPanel px={0}>
						<Flex h="100%">
							<Box flex="0 0 50%">
								<Label
									textAlign={align}
									fontSize="xs"
									fontWeight="bold"
									mb={4}
								>
									<FormattedMessage id="settings.display.options" />
								</Label>
								<Stack direction="column" gap={1} mb={8}>
									<Box>
										<Checkbox
											colorScheme="orange"
											isChecked={showFees}
											onChange={(e) => handleShowFeesChange(e)}
										>
											<SettingsOptionLabel>
												<FormattedMessage id="settings.display.show_fees_section" />
											</SettingsOptionLabel>
										</Checkbox>
									</Box>
									
									<Box>
										<Checkbox
											colorScheme="orange"
											isChecked={feesRTL}
											onChange={(e) => handleFeesRTLChange(e)}
										>
											<SettingsOptionLabel>
												<FormattedMessage id="settings.display.fees_right_to_left" />
											</SettingsOptionLabel>
										</Checkbox>
									</Box>
									
									<Box>
										<Checkbox
											colorScheme="orange"
											isChecked={showMiningPool}
											onChange={(e) => handleShowMiningPoolChange(e)}
										>
											<SettingsOptionLabel>
												<FormattedMessage id="settings.display.show_mining_pool" />
											</SettingsOptionLabel>
										</Checkbox>
									</Box>
								</Stack>

								<Flex direction="column">
									<Label
										textAlign={align}
										fontSize="xs"
										fontWeight="bold"
										mb={4}
									>
										<FormattedMessage id="settings.units.exchange_rate" />
									</Label>
									<Stack direction="column" gap={1}>
										<Box>
											<Checkbox
												colorScheme="orange"
												isChecked={showCuckBucks}
												onChange={(e) => handleShowCuckBucksChange(e)}
											>
												<SettingsOptionLabel>
													{userCurrency}{EXCHANGE_RATE_USDBTC_LABEL}
												</SettingsOptionLabel>
											</Checkbox>
										</Box>

										<Box>
											<Checkbox
												colorScheme="orange"
												isChecked={showMoscowTime}
												onChange={(e) => handleShowMoscowTimeChange(e)}
											>
												<SettingsOptionLabel>
													{EXCHANGE_RATE_SATSUSD_LABEL}{userCurrency}
												</SettingsOptionLabel>
											</Checkbox>
										</Box>

										<Box>
											<Checkbox
												colorScheme="orange"
												isChecked={showMarketCap}
												onChange={(e) => handleShowMarketCapChange(e)}
											>
												<SettingsOptionLabel>
													<FormattedMessage id="settings.units.market_cap" />
												</SettingsOptionLabel>
											</Checkbox>
										</Box>
									</Stack>
								</Flex>
							</Box>
							
							<Box flex="0 0 50%" pl={5}>
								<Flex direction="column">
									<Label
										textAlign={align}
										fontSize="xs"
										fontWeight="bold"
										mb={4}
									>
										<FormattedMessage id="settings.animations.label" />
									</Label>
									
									<Flex
										align="center"
										gap={2}
										mt={-1}
										mb={4}
									>
										<Label
											textAlign={align}
											fontSize="xs"
											fontWeight="bold"
										>
											<FormattedMessage id="settings.sounds.off" />
										</Label>

										<Switch
											isChecked={!reducedMotion}
											colorScheme="orange"
											onChange={handleToggleAnimations}
											size="md"
										/>

										<Label
											textAlign={align}
											fontSize="xs"
											fontWeight="bold"
										>
											<FormattedMessage id="settings.sounds.on" />
										</Label>
									</Flex>
									
									<Stack direction="column" gap={1} mb={10}>
										<Box>
											<Checkbox
												colorScheme="orange"
												isChecked={fullNewBlockRipple}
												onChange={(e) => handleNewBlockRippleChange(e)}
											>
												<SettingsOptionLabel>
													<FormattedMessage id="settings.animations.full_ripple" />
												</SettingsOptionLabel>
											</Checkbox>
										</Box>

										<Box>
											<Checkbox
												colorScheme="orange"
												isChecked={disableHalvingPulse}
												onChange={(e) => handleHalvingPulseChange(e)}
											>
												<SettingsOptionLabel>
													🟠 <FormattedMessage id="settings.animations.pulse_disable" />
												</SettingsOptionLabel>
											</Checkbox>
										</Box>
										
										<Box>
											<Checkbox
												colorScheme="orange"
												isChecked={disableDifficultyPulse}
												onChange={(e) => handleDifficultyPulseChange(e)}
											>
												<SettingsOptionLabel>
													🔴 <FormattedMessage id="settings.animations.pulse_disable" />
												</SettingsOptionLabel>
											</Checkbox>
										</Box>
									</Stack>
								</Flex>

								<Flex direction="column">
									<Label
										textAlign={align}
										fontSize="xs"
										fontWeight="bold"
										mb={4}
									>
										<FormattedMessage id="settings.color_mode" />
									</Label>

									<Flex align="center" gap={2} mt={-1}>
										<Box
											onClick={colorMode === 'dark' ? undefined : toggleColorMode}
											color={colorMode === 'dark' ? BITCOIN_ORANGE : iconColor}
											cursor={colorMode === 'dark' ? 'default': 'pointer'}
											_hover={{
												color: BITCOIN_ORANGE,
											}}
										>
											<MoonIcon width={16} />
										</Box>
										

										<Switch
											isChecked={colorMode === 'light'}
											colorScheme="orange"
											onChange={toggleColorMode}
											size="md"
										/>

										<Box
											onClick={colorMode === 'light' ? undefined : toggleColorMode}
											color={colorMode === 'light' ? BITCOIN_ORANGE : iconColor}
											cursor={colorMode === 'light' ? 'default': 'pointer'}
											_hover={{
												color: BITCOIN_ORANGE,
											}}
										>
											<SunIcon width={16} />
										</Box>
									</Flex>
								</Flex>
							</Box>
						</Flex>
					</TabPanel>

					<TabPanel px={0}>
						<Flex>
							<Box flex="0 0 50%">
								<Stack direction="column" gap={7}>
									<Box>
										<Label fontSize="xs" fontWeight="bold" mb={3}>
											<FormattedMessage id="settings.locale.time_format" />
										</Label>
										<RadioGroup
											onChange={handleTimeFormatChange}
											value={timeFormat}
											defaultValue={TIME_FORMAT.h12}
											colorScheme="orange"
										>
											<Stack direction="column" gap={1}>
												<Radio value={TIME_FORMAT.h12}>
													<SettingsOptionLabel>
														<FormattedMessage id="settings.locale.12hour" />
													</SettingsOptionLabel>
												</Radio>

												<Radio value={TIME_FORMAT.h24}>
													<SettingsOptionLabel>
														<FormattedMessage id="settings.locale.24hour" />
													</SettingsOptionLabel>
												</Radio>
											</Stack>
										</RadioGroup>
									</Box>

									<Box>
										<Label fontSize="xs" fontWeight="bold" mb={1}>
											<FormattedMessage id="settings.locale.language" />
										</Label>
										<Flex>
											<LanguageSetterButton />
										</Flex>
									</Box>

									<Box>
										<Label fontSize="xs" fontWeight="bold">
											<FormattedMessage id="settings.currency" />
										</Label>
										<Flex>
											<CurrencySetter />
										</Flex>
									</Box>
								</Stack>
							</Box>

							<Box flex="0 0 50%">
								<Label fontSize="xs" fontWeight="bold" mb={3}>
									<FormattedMessage id="settings.locale.date_format" />
								</Label>
								<RadioGroup
									onChange={handleDateFormatChange}
									value={dateFormat}
									defaultValue={DATE_FORMAT.default}
									colorScheme="orange"
								>
									<Stack direction="column" gap={1}>
										<Radio value={DATE_FORMAT.default}>
											<SettingsOptionLabel>
												{format(date, FORMATS_CONFIG.defaultDate, { locale: dateLocales[userLocale] })}
											</SettingsOptionLabel>
										</Radio>

										<Radio value={DATE_FORMAT.alt}>
											<SettingsOptionLabel>
												{format(date, FORMATS_CONFIG.altDate, { locale: dateLocales[userLocale] })}
											</SettingsOptionLabel>
										</Radio>

										<Radio value={DATE_FORMAT.europe}>
											<SettingsOptionLabel>
												{format(date, FORMATS_CONFIG.europeDate)}
											</SettingsOptionLabel>
										</Radio>

										<Radio value={DATE_FORMAT.universal}>
											<SettingsOptionLabel>
												{format(date, FORMATS_CONFIG.universalDate)}
											</SettingsOptionLabel>
										</Radio>
									</Stack>
								</RadioGroup>
							</Box>
						</Flex>
					</TabPanel>

					<TabPanel px={0}>
						<Flex align="flex-start">
							<Box flex="0 0 50%">
								<Label fontSize="xs" fontWeight="bold" mb={4}>
									<FormattedMessage id="settings.sounds.sounds_are" />:
								</Label>
								<Flex align="center" gap={2}>
									<Switch
										isChecked={audioActive}
										onChange={(e) => handleAudioActiveChange(e)}
										colorScheme="orange"
										size="md"
									/>
									<SettingsOptionLabel>
										{AUDIO_STATUS}
									</SettingsOptionLabel>
								</Flex>
							</Box>

							<Box flex="0 0 50%">
								<Label fontSize="xs" fontWeight="bold" mb={4}>
									<FormattedMessage id="settings.sounds.new_block_sound" />
								</Label>
								<RadioGroup
									onChange={handleAudioSoundChange}
									value={audioSound}
									defaultValue={AUDIO_SOUNDS.drop}
									colorScheme="orange"
								>
									<Stack direction="column" gap={1}>
										<Radio value={AUDIO_SOUNDS.drop}>
											<SettingsOptionLabel>
												<FormattedMessage id="settings.sounds.water_drop" />
											</SettingsOptionLabel>
										</Radio>

										<Radio value={AUDIO_SOUNDS.bell}>
											<SettingsOptionLabel>
												<FormattedMessage id="settings.sounds.bell" />
											</SettingsOptionLabel>
										</Radio>

										<Radio value={AUDIO_SOUNDS.doorbell}>
											<SettingsOptionLabel>
												<FormattedMessage id="settings.sounds.door_chime" />
											</SettingsOptionLabel>
										</Radio>

										<Radio value={AUDIO_SOUNDS.gong}>
											<SettingsOptionLabel>
												<FormattedMessage id="settings.sounds.cymbal" />
											</SettingsOptionLabel>
										</Radio>

										<Radio value={AUDIO_SOUNDS.whoosh}>
											<SettingsOptionLabel>
												<FormattedMessage id="settings.sounds.whoosh" />
											</SettingsOptionLabel>
										</Radio>
									</Stack>
								</RadioGroup>
							</Box>
						</Flex>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex>
	)
}
