import type { PropsWithChildren } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import {
	circlesWrapWidthState,
	confBgImgState,
	confModeState,
	landscapeOrientationState,
} from '../../../state'

export const CirclesSquareWrapV3 = ({ children }: PropsWithChildren) => {
	const circlesWrapWidth = useRecoilValue(circlesWrapWidthState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const confMode = useRecoilValue(confModeState)
	const confBgImg = useRecoilValue(confBgImgState)
	
	const responsiveHeight = landscapeOrientation ? '100%' : undefined
	const maxH = landscapeOrientation ? `${circlesWrapWidth}px` : undefined

	const isConfBackground = confMode && confBgImg

	return (
		<Flex
			flexShrink={1}
			className="tc-circle-restrictor"
			w="100%"
			h={responsiveHeight}
			maxW={`${circlesWrapWidth}px`}
			maxH={maxH}
		>
			<Box
				className="tc-circles-wrap"
				pos="relative"
				zIndex={100}
				w="100%"
				_before={{
					display: 'block',
					content: '""',
					pb: '100%',
				}}
			>
				<Flex
					className="tc-circles-wrap-inner"
					pos="absolute"
					align="center"
					justify="center"
					top={0}
					left={0}
					right={0}
					bottom={0}
				>
					{isConfBackground && (
						<Box
							pos="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							borderRadius="50%"
							bg="black"
							filter="blur(20px)"
						/>
					)}
					
					{children}
				</Flex>
			</Box>
		</Flex>
	)
}
