import { atom } from 'recoil'

export type ArtBlock = {
    b: number
    g: string
    a?: string
}

export type Artist = {
    cats: string[]
    link: string
    path: string
    name: string
}

export type Embed = {
	attribution: string
	description?: string
	imageHeight: number
	imageUrl: string
	imageWidth: number
	link?: string
	title: string
	videoSrc?: string
	buttonLabel?: string
	slides?: string[]
}

export const blockEmbedState = atom({
	key: 'blockEmbed',
	default: null as ArtBlock | null
})

export const artBlocksState = atom({
	key: 'artBlocks',
	default: [] as ArtBlock[],
})

export const artistsState = atom({
	key: 'artists',
	default: [] as Artist[]
})
