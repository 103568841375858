import { useState, useEffect, useRef } from 'react'

export const useIsScrolling = () => {
	const [isScrolling, setIsScrolling] = useState(false)
	const scrollPosRef = useRef(0)
	const rafRef = useRef<number | null>(null)
  
	useEffect(() => {
		const checkScrolling = () => {
			const currentScrollPos = window.scrollY
	
			if (currentScrollPos !== scrollPosRef.current) {
				scrollPosRef.current = currentScrollPos
				setIsScrolling(true)
			} else {
				setIsScrolling(false)
			}
	
			rafRef.current = requestAnimationFrame(checkScrolling)
		}
  
		rafRef.current = requestAnimationFrame(checkScrolling)
  
		return () => {
			if (rafRef.current) {
				cancelAnimationFrame(rafRef.current)
			}
		}
	}, [])
  
	return isScrolling
}
