import {
	Flex,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { FormattedMessage } from 'react-intl'

import {
	circlesBreakpointState,
	chainHeightState,
	totalAdressesState,
	totalUtxosState,
	totalDiskState,
	totalTxnsCountState,
} from '../../../../state'
import { Label } from '../../../shared'
import { getBreakpointValue, abbreviateDiskSize, abbreviateNumber } from '../../../../utils'
import { primaryDataFontSize } from './dataComponents.constants'
import { DataBox } from '../../../layout/sidebar/SidebarSection'

export const DataChainstateFooter = () => {
	const chainHeight = useRecoilValue(chainHeightState)
	const textColorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const textColorAlt2 = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	
	return (
		<Flex
			direction="column"
			align="center"
			gap={1}
		>
			<Label color={textColorAlt2} fontWeight="semibold">
				&rarr; <FormattedMessage id="info.terms.title.block" /> <span style={{ color: textColorAlt, fontWeight: 'bold' }}>{chainHeight}</span>, <FormattedMessage id="data.chainstate.updated_daily" />
			</Label>
		</Flex>
	)
}

export const DataChainstate = () => {
	const chainHeight = useRecoilValue(chainHeightState)
	const totalAddresses = useRecoilValue(totalAdressesState)
	const totalUtxos = useRecoilValue(totalUtxosState)
	const totalTxnsCount = useRecoilValue(totalTxnsCountState)
	const totalDisk = useRecoilValue(totalDiskState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const textColor = useColorModeValue('black', 'white')

	if (chainHeight === 0) return null

	return (
		<>
			<DataBox>
				<Label>
					<FormattedMessage id="data.chainstate.addresses" />
				</Label>

				<Text
					lineHeight={fontSize}
					mt={1}
					color={textColor}
					fontSize={fontSize}
					fontWeight="bold"
					whiteSpace="nowrap"
					display="flex"
					align="center"
					gap="3px"
				>
					{abbreviateNumber(totalAddresses)}
				</Text>
			</DataBox>
			
			<DataBox>
				<Label>
					<FormattedMessage id="data.chainstate.utxo_count" />
				</Label>

				<Text
					lineHeight={fontSize}
					mt={1}
					color={textColor}
					fontSize={fontSize}
					fontWeight="bold"
					whiteSpace="nowrap"
					display="flex"
					align="center"
					gap="3px"
				>
					{abbreviateNumber(totalUtxos)}
				</Text>
			</DataBox>
			
			<DataBox>
				<Label>
					<FormattedMessage id="data.chainstate.transactions" />
				</Label>

				<Text
					lineHeight={fontSize}
					mt={1}
					color={textColor}
					fontSize={fontSize}
					fontWeight="bold"
					whiteSpace="nowrap"
					display="flex"
					align="center"
					gap="3px"
				>
					{abbreviateNumber(totalTxnsCount)}
				</Text>
			</DataBox>

			<DataBox>
				<Label>
					<FormattedMessage id="data.chainstate.total_disk" />
				</Label>

				<Text
					lineHeight={fontSize}
					mt={1}
					color={textColor}
					fontSize={fontSize}
					fontWeight="bold"
					whiteSpace="nowrap"
					display="flex"
					align="center"
					gap="3px"
				>
					{abbreviateDiskSize(totalDisk)}
				</Text>
			</DataBox>
		</>
	)
}
