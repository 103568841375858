import { defineMessages } from 'react-intl'

// NEW LOCALE UPDATE HERE
// @ts-ignore
import ar from './strings/ar.json'
// @ts-ignore
import de from './strings/de.json'
// @ts-ignore
import en from './strings/en.json'
// @ts-ignore
import es from './strings/es.json'
// @ts-ignore
import ca from './strings/ca.json'
// @ts-ignore
import fr from './strings/fr.json'
// @ts-ignore
import gr from './strings/gr.json'
// @ts-ignore
import it from './strings/it.json'
// @ts-ignore
import nl from './strings/nl.json'
// @ts-ignore
import pl from './strings/pl.json'
// @ts-ignore
import pt from './strings/pt.json'
// @ts-ignore
import ru from './strings/ru.json'
// @ts-ignore
import sv from './strings/sv.json'
// @ts-ignore
import tr from './strings/tr.json'
// @ts-ignore
import zh from './strings/zh.json'

// NEW LOCALE UPDATE HERE
export type UserLocale = 'ar' | 'ca' | 'de' | 'en' | 'es' | 'fr' | 'gr' | 'it' | 'nl' | 'pl' | 'pt' | 'ru' | 'sv' | 'tr' | 'zh'

// NEW LOCALE UPDATE HERE
export const ACTIVE_USER_LOCALES = ['ar', 'ca', 'de', 'en', 'es', 'fr', 'gr', 'it', 'nl', 'pl', 'pt', 'ru', 'sv', 'tr', 'zh']

// NEW LOCALE UPDATE HERE
const strings = {
	ar,
	ca,
	de,
	en,
	es,
	fr,
	gr,
	it,
	nl,
	pl,
	pt,
	ru,
	sv,
	tr,
	zh,
}

export const rtlLocales = ['ar']

const getMessages = (lang: UserLocale) => {
	const stringsToParse = lang ? strings[lang] : strings['en']
	return defineMessages(JSON.parse(JSON.stringify(stringsToParse)))
}

export default getMessages
