export type BlockEmbed = {
	block: number
	gThumbUrl: string
	thumbUrl: string
	imageUrl: string
	imageWidth: number
	imageHeight: number
	attribution: string
	cats: string[]
	title?: string
	description?: string
	link?: string
	buttonLabel?: string
	videoSrc?: string
}

type CategoryColors = {
	[key: string]: string
}

export type GallerySort = 'ASC' | 'DESC' | 'BLOCK_ASC' | 'BLOCK_DESC'
export const GALLERY_SORT_OPTIONS = {
	asc: 'ASC',
	desc: 'DESC',
	blockAsc: 'BLOCK_ASC',
	blockDesc: 'BLOCK_DESC',
}

export const CATEGORIES = {
	physical: 'physical',
	digital: 'digital',
	literature: 'literature',
	video: 'video',
	music: 'music',
}

export const CATEGORY_COLORS: CategoryColors = {
	physical: '#ae0642',
	digital: '#781b92',
	literature: '#582bc2',
	video: '#2c43b6',
	music: '#0359a6',
}
