import { useState } from 'react'
import {
	Button,
	useColorModeValue,
	chakra,
	shouldForwardProp,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { SearchIcon } from '@chakra-ui/icons'
import { useRecoilValue } from 'recoil'

import {
	circlesBreakpointState,
	editingBlockHeightState,
	scrubbingState,
	showBlockRippleState,
} from '../../../state'
import { BITCOIN_ORANGE, blurInAnimation } from '../../../constants'
import { getBreakpointValue } from '../../../utils'
import {
	buttonBreaks,
	buttonIconSize,
	buttonBottom,
} from './searchButton.constants'
import { useAppSelector } from '../../../hooks'

interface SearchButtonProps {
	onClick: () => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const TCSearchButton = ({ onClick }: SearchButtonProps) => {
	const { reducedMotion } = useAppSelector(({ settings }) => settings)
	const scrubbing = useRecoilValue(scrubbingState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const editingBlockHeight = useRecoilValue(editingBlockHeightState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const [isHover, setIsHover] = useState(false)
	const color = useColorModeValue('black', 'white')
	const bg = useColorModeValue('white', 'black')
	const interactionStyle = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}
	const responsiveButtonHeight = getBreakpointValue(buttonBreaks, circlesBreakpoint)
	const iconSize = getBreakpointValue(buttonIconSize, circlesBreakpoint)
	const responsiveBottom = getBreakpointValue(buttonBottom, circlesBreakpoint)
	const hidden = scrubbing || showBlockRipple
	const disabled = hidden || editingBlockHeight
	const opacity = hidden ? 0.1 : 1
	const filter = hidden ? 'blur(4px)' : 'blur(0)'

	const buttonAnimate = reducedMotion
		? undefined
		: {
			opacity: [0.25, 1, 0.5, 1, 0.25],
		}
	const buttonTransition = reducedMotion
		? undefined
		: {
			duration: 7,
			ease: 'linear',
			repeat: Infinity,
			repeatType: 'loop',
		}

	return (
		<Button
			className="tc-search-button"
			role="group"
			onClick={onClick}
			pos="absolute"
			zIndex={1200}
			left={0}
			bottom={responsiveBottom}
			variant="outline"
			w={responsiveButtonHeight}
			h={responsiveButtonHeight}
			bg={bg}
			textTransform="uppercase"
			color={color}
			borderColor={color}
			borderWidth={2}
			borderRadius="50%"
			opacity={opacity}
			filter={filter}
			overflow="hidden"
			animation={blurInAnimation}
			disabled={disabled}
			transition="all 0.75s easeIn"
			_active={interactionStyle}
			_hover={interactionStyle}
		>
			<MotionBox
				animate={isHover ? undefined : buttonAnimate}
				// @ts-ignore
				transition={isHover ? undefined : buttonTransition}
				onHoverStart={() => setIsHover(true)}
				onHoverEnd={() => setIsHover(false)}
			>
				<SearchIcon
					pos="relative"
					zIndex={2}
					boxSize={iconSize}
					transition="all 0.75s easeIn"
					_groupHover={{
						transform: 'scale(1.15)'
					}}
				/>
			</MotionBox>
		</Button>
	)
}
