export const EPOCH_BLOCKS = 210000
export const EPOCH_1_BLOCK = EPOCH_BLOCKS * 0
export const EPOCH_2_BLOCK = EPOCH_BLOCKS * 1
export const EPOCH_3_BLOCK = EPOCH_BLOCKS * 2
export const EPOCH_4_BLOCK = EPOCH_BLOCKS * 3
export const EPOCH_5_BLOCK = EPOCH_BLOCKS * 4
export const EPOCH_6_BLOCK = EPOCH_BLOCKS * 5

export const NUM_EPOCHS = 32

export const TEN_MINUTES_MS = 600000

// this needs to be rethought, returns funky values after index 25
export const SUBSIDY_MAP = (() => {
	const subsidyMap: Array<number> = []
	for (let epoch = 0; epoch < NUM_EPOCHS; epoch++) {
		const reward = Number((50 / (Math.pow(2, epoch))).toFixed(8))
		subsidyMap.push(reward)
	}
	return subsidyMap
})()

export const DIFFICULTY_ADJUSTMENT_BLOCKS = 2016

export const ONE_DAY_BLOCKS = 144

export const SATS_PER_BITCOIN = 100000000

export const TOTAL_BITCOIN_SUPPLY = 21000000

export const BITCOIN_ORANGE = '#f7931a'
export const getBitcoinOrangeAlpha = (opacity = 1) => `rgba(247, 147, 26, ${opacity})`

export const GENESIS_BLOCK_DATE = '2009-01-03T10:15:00+00:00'
export const GENESIS_TIMESTAMP = '1231006505'
