import { useEffect } from 'react'
import type { PropsWithChildren } from 'react'
import {
	Flex,
	Text,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'

import { useAppSelector, useAppDispatch } from '../../../hooks'
import {
	CURRENCY_LABEL_MAP,
	CURRENCY_SYMBOL_MAP,
	type UserCurrency,
} from './currencies'
import { CloseButtonLabel, Label, ModalOverlay } from '../../shared'
import { BITCOIN_ORANGE, menuLabelFontSize } from '../../../constants'
import { setUserCurrency } from './currenciesReducer'
import { getBreakpointValue } from '../../../utils'
import { circlesBreakpointState } from '../../../state'

interface CurrencyButtonProps {
	onClick: () => void
}

interface CurrencySymbolProps {
	currency: string
}

type CurrencyOptionsProps = PropsWithChildren & { value: string }

export const CurrencySetter = () => {
	const intl = useIntl()
	const dispatch = useAppDispatch()
	const { isOpen, onOpen, onClose } = useDisclosure()
	// @ts-ignore
	const { userCurrency } = useAppSelector(({ currencies }) => currencies)
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255, 0.5)')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.4)', 'rgba(255,255,255, 0.4)')
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const labelFontSize = getBreakpointValue(menuLabelFontSize, circlesBreakpoint)

	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	const handleCurrencyChange = (newValue: UserCurrency) => {
		dispatch(setUserCurrency(newValue))
	}

	const CurrencyButton = ({ onClick }: CurrencyButtonProps) => {
		// const symbol = CURRENCY_SYMBOL_MAP[userCurrency]
		const label = CURRENCY_LABEL_MAP[userCurrency]
		return (
			<Button
				onClick={onClick}
				variant="unstyled"
				size="sm"
				role="group"
				aria-label={intl.formatMessage({ id: 'settings.currency' })}
			>
				<Flex
					align="center"
					gap={1}
				>
					{/* {symbol} */}

					<Label
						fontWeight="bold"
						mr="4px"
						fontSize={labelFontSize}
						color="currentColor"
						transition="all 0.21s ease"
						_groupHover={{
							color: BITCOIN_ORANGE,
						}}
					>
						{label}
					</Label>
				</Flex>
			</Button>
		)
	}

	const CurrencyOptionV3 = ({ value, children, ...rest }: CurrencyOptionsProps) => {
		const [green100, lightgreen80] = useToken('colors', ['green100', 'lightgreen80'])
		const green = useColorModeValue(lightgreen80, green100)
		const borderColor = value === userCurrency ? green : colorAlt
		const borderSize = value === userCurrency ? 2 : 1
		const buttonInteractionStyles = {
			borderColor: value === userCurrency ? green : BITCOIN_ORANGE,
			color: value === userCurrency ? color : BITCOIN_ORANGE,
		}
		const cursor = value === userCurrency ? 'default' : 'pointer'

		return (
			<Button
				role="group"
				flex="1 1 30%"
				onClick={() => {
					onClose()
					handleCurrencyChange(value as UserCurrency)
				}}
				variant="outline"
				size="sm"
				color={color}
				borderWidth={borderSize}
				borderColor={borderColor}
				cursor={cursor}
				_hover={buttonInteractionStyles}
				_active={buttonInteractionStyles}
				{...rest}
			>
				<Flex align="center">
					{children}
				</Flex>
			</Button>
		)
	}

	const CurrencySymbol = ({ currency }: CurrencySymbolProps) => {
		return (
			<Text
				mr="7px"
				color={colorAlt2}
				fontSize="21px"
				fontWeight="normal"
				transition="all 0.21 ease"
				_groupHover={{
					color: 'currentColor',
				}}
			>
				{currency}
			</Text>
		)
	}

	useEffect(() => {
		if (!userCurrency) {
			setUserCurrency('usd')
		}
	}, [userCurrency])

	return (
		<>
			<CurrencyButton onClick={onOpen} />

			<Modal
				key="language"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="sm"
				blockScrollOnMount={false}
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					borderRadius="lg"
					zIndex={3334}
				>
					<ModalBody mt={4}>
						<Flex
							direction="row"
							justify="center"
							wrap="wrap"
							maxHeight="356px"
							overflowY="auto"
							gap={3}
						>
							{/* <CurrencyOptionV3
								value="ars"
								aria-label={CURRENCY_LABEL_MAP.ars}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.ars} /> {CURRENCY_LABEL_MAP.ars}
							</CurrencyOptionV3> */}

							<CurrencyOptionV3
								value="aud"
								aria-label={CURRENCY_LABEL_MAP.aud}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.aud} /> {CURRENCY_LABEL_MAP.aud}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="brl"
								aria-label={CURRENCY_LABEL_MAP.brl}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.brl} /> {CURRENCY_LABEL_MAP.brl}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="cad"
								aria-label={CURRENCY_LABEL_MAP.cad}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.cad} /> {CURRENCY_LABEL_MAP.cad}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="chf"
								aria-label={CURRENCY_LABEL_MAP.chf}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.chf} /> {CURRENCY_LABEL_MAP.chf}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="cny"
								aria-label={CURRENCY_LABEL_MAP.cny}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.cny} /> {CURRENCY_LABEL_MAP.cny}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="eur"
								aria-label={CURRENCY_LABEL_MAP.eur}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.eur} /> {CURRENCY_LABEL_MAP.eur}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="gbp"
								aria-label={CURRENCY_LABEL_MAP.gbp}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.gbp} /> {CURRENCY_LABEL_MAP.gbp}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="inr"
								aria-label={CURRENCY_LABEL_MAP.inr}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.inr} /> {CURRENCY_LABEL_MAP.inr}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="jpy"
								aria-label={CURRENCY_LABEL_MAP.jpy}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.jpy} /> {CURRENCY_LABEL_MAP.jpy}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="mxn"
								aria-label={CURRENCY_LABEL_MAP.mxn}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.mxn} /> {CURRENCY_LABEL_MAP.mxn}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="ngn"
								aria-label={CURRENCY_LABEL_MAP.ngn}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.ngn} /> {CURRENCY_LABEL_MAP.ngn}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="pln"
								aria-label={CURRENCY_LABEL_MAP.pln}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.pln} /> {CURRENCY_LABEL_MAP.pln}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="rub"
								aria-label={CURRENCY_LABEL_MAP.rub}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.rub} /> {CURRENCY_LABEL_MAP.rub}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="sek"
								aria-label={CURRENCY_LABEL_MAP.sek}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.sek} /> {CURRENCY_LABEL_MAP.sek}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="thb"
								aria-label={CURRENCY_LABEL_MAP.thb}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.thb} /> {CURRENCY_LABEL_MAP.thb}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="try"
								aria-label={CURRENCY_LABEL_MAP.try}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.try} /> {CURRENCY_LABEL_MAP.try}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="usd"
								aria-label={CURRENCY_LABEL_MAP.usd}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.usd} /> {CURRENCY_LABEL_MAP.usd}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="vnd"
								aria-label={CURRENCY_LABEL_MAP.vnd}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.vnd} /> {CURRENCY_LABEL_MAP.vnd}
							</CurrencyOptionV3>

							<CurrencyOptionV3
								value="zar"
								aria-label={CURRENCY_LABEL_MAP.zar}
							>
								<CurrencySymbol currency={CURRENCY_SYMBOL_MAP.zar} /> {CURRENCY_LABEL_MAP.zar}
							</CurrencyOptionV3>
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Flex
							w="100%"
							justify="flex-end"
							align="flex-end"
							color={color}
						>
							<Button
								onClick={onClose}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<CloseButtonLabel />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}