import type { FC, PropsWithChildren } from 'react'
import {
	Flex,
	Box,
	useColorModeValue,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import {
	TCHeader,
	TCFooter,
	HalvingCountdown,
} from './index'
import {
	circlesBreakpointState,
	landscapeOrientationState,
	contentHeightState,
	confBgImgState,
	confModeState,
	confBgImgBlendState,
} from '../../state'
import { getBreakpointValue } from '../../utils'
import {
	PADDING_BREAKPOINTS,
	PADDING_BREAKPOINTS_PORTRAIT,
} from '../../constants'

export const MasterWrap: FC<PropsWithChildren> = ({ children }) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const contentHeight = useRecoilValue(contentHeightState)
	const confMode = useRecoilValue(confModeState)
	const confBgImg = useRecoilValue(confBgImgState)
	const confBgImgBlend = useRecoilValue(confBgImgBlendState)

	const bg = useColorModeValue('linear-gradient(#eee, #eee, #fff, #fff)', 'black')
	const contentDirection = landscapeOrientation ? 'row' : 'column'
	const responsiveJustify = landscapeOrientation
		? getBreakpointValue({ base: 'flex-start', md: 'space-between' }, circlesBreakpoint) as ResponsiveValue<'space-between' | 'flex-start'>
		: undefined
	const responsiveGapContent = landscapeOrientation
		? getBreakpointValue(PADDING_BREAKPOINTS, circlesBreakpoint)
		: getBreakpointValue(PADDING_BREAKPOINTS_PORTRAIT, circlesBreakpoint)
	const contentPx = landscapeOrientation
		? getBreakpointValue(PADDING_BREAKPOINTS, circlesBreakpoint)
		: getBreakpointValue(PADDING_BREAKPOINTS_PORTRAIT, circlesBreakpoint)
	const responsiveContentHeight = landscapeOrientation ? contentHeight : undefined
	const responsivePaddingTop = landscapeOrientation ? 0 : 2
	const consitionalPos = landscapeOrientation ? 'none' : 'block'
	const countdownDisplay = getBreakpointValue({ base: consitionalPos, sm: 'none' }, circlesBreakpoint) as ResponsiveValue<'block' | 'none'>

	const isConfBackground = confMode && confBgImg
	const bgImage = isConfBackground ? `url(${confBgImg})` : undefined
	const bgPos = isConfBackground ? 'center top' : undefined
	const bgRepeat = isConfBackground ? 'no-repeat' : undefined
	const bgSize = isConfBackground ? 'cover' : undefined
	const bgBlend = isConfBackground && confBgImgBlend ? confBgImgBlend : undefined

	return (
		<Flex
			className="tc-master-wrap"
			direction="column"
			align="center"
			justify={responsiveJustify}
			bg={bg}
			minH="100vh"
			w="100%"
			p="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
			overflow="hidden"
			bgImage={bgImage}
			bgPos={bgPos}
			bgRepeat={bgRepeat}
			bgSize={bgSize}
			bgBlendMode={bgBlend}
		>
			<TCHeader />

			<Box display={countdownDisplay}>
				<HalvingCountdown posTop={0} />
			</Box>

			<Flex
				className="tc-inner-content-wrap"
				w="100%"
				h={responsiveContentHeight}
				flex="1 0 auto"
				pos="relative"
				direction={contentDirection}
				justify={responsiveJustify}
				align="center"
				gap={responsiveGapContent}
				px={contentPx}
				pt={responsivePaddingTop}
				style={{
					perspective: '800px',
					perspectiveOrigin: 'top center',
				}}
			>
				{children}
			</Flex>

			<TCFooter />
		</Flex>
	)
}