import { CIRCLE_SQUARE_MAX, MIN_LANDSCAPE_CIRCLE_HEIGHT } from '../constants'

export const getRotationAngle = (angleRatio: number) => angleRatio * 360

export type CircleBreakpointsStateKey = 'base' | 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'jumbo'

export type CircleBreakpointsStateValue = string | number
// eslint-disable-next-line no-unused-vars
type CircleBreakpointsStateObject = { [K in CircleBreakpointsStateKey]?: CircleBreakpointsStateValue }

export const getBreakpointValue = (obj: CircleBreakpointsStateObject, key: CircleBreakpointsStateKey): CircleBreakpointsStateValue | undefined => {
	const orderedKeys: CircleBreakpointsStateKey[] = ['base', 'xxxs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'jumbo']
	const keyIndex = orderedKeys.indexOf(key)

	for (let i = keyIndex; i >= 0; i--) {
		if (orderedKeys[i] in obj) {
			return obj[orderedKeys[i]]
		}
	}

	return undefined
}

export const calculateMagicNumber = (windowWidth: number, windowHeight: number, confMode = false) => {
	const XS_BREAKPOINT = 400
	const LG_BREAKPOINT = 660
	const XL_BREAKPOINT = 760
	const LARGE_HFC_HEIGHT = confMode ? 90 : 120
	const SMALL_HFC_HEIGHT = confMode ? 60 : 90
	const LARGE_MIN_HEIGHT = LARGE_HFC_HEIGHT + LG_BREAKPOINT
	const LG_P = 30
	const MD_P = 16
	const SM_P = 10
	const XL_MIN_WIDTH = (LG_P * 2) + XL_BREAKPOINT
	const XS_MIN_WIDTH = (MD_P * 2) + XS_BREAKPOINT

	const landscapeOrientation = windowWidth > windowHeight
	const isLargeLandscapeLayout = windowHeight >= LARGE_MIN_HEIGHT
	const isLargePortraitLayout = windowWidth >= XL_MIN_WIDTH
	const isMediumPortraitLayout = (windowWidth >= XS_MIN_WIDTH) && !isLargePortraitLayout
	const buffer = landscapeOrientation ? 20 : 0

	const magicNumber = landscapeOrientation
		// landscape measure height minus header/footer & controls
		? isLargeLandscapeLayout
			? (windowHeight - LARGE_HFC_HEIGHT) > CIRCLE_SQUARE_MAX
				? CIRCLE_SQUARE_MAX
				: windowHeight - LARGE_HFC_HEIGHT
			: (windowHeight - SMALL_HFC_HEIGHT) < MIN_LANDSCAPE_CIRCLE_HEIGHT
				? MIN_LANDSCAPE_CIRCLE_HEIGHT
				: windowHeight - SMALL_HFC_HEIGHT
		// portrait measure width minus padding
		: isLargePortraitLayout
			? (windowWidth - (LG_P * 2)) > CIRCLE_SQUARE_MAX
				? CIRCLE_SQUARE_MAX
				: windowWidth - (LG_P * 2)
			: isMediumPortraitLayout
				? windowWidth - (MD_P * 2)
				: windowWidth - (SM_P * 2)

	return magicNumber - buffer
}
