import { useEffect } from 'react'
import {
	Flex,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'

import { BITCOIN_ORANGE } from '../../../constants'
import { TCSearchButton } from './TCSearchButton'
import {
	CloseButtonLabel,
	EpochButtons,
	ModalOverlay,
} from '../../shared'
import {
	confModeState,
	editingBlockHeightState,
	scrubbingState,
} from '../../../state'
import { useAppSelector } from '../../../hooks'

interface TCSearchProps {
	onOpenDatePickerModal: () => void
	// eslint-disable-next-line no-unused-vars
	handleScrubChange: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	handleScrubChangeEnd: (x: number) => void
}

export const TCSearch = ({
	onOpenDatePickerModal,
	handleScrubChange,
	handleScrubChangeEnd,
}: TCSearchProps) => {
	const location = useLocation()
	const navigate = useNavigate()
	const { userLocale } = useAppSelector((state) => state.settings)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const setEditingBlockHeight = useSetRecoilState(editingBlockHeightState)
	const confMode = useRecoilValue(confModeState)
	const setScrubbing = useSetRecoilState(scrubbingState)
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	
	const handleFocusBlockHeightInput = () => {
		setTimeout(() => {
			const blockHeightInput = document.getElementById('block-height-input')
			blockHeightInput?.focus()
		}, 250)
	}

	const handleChangeBlock = (x: number) => {
		onClose()
		setScrubbing(true)
		handleScrubChange(x)
		handleScrubChangeEnd(x)
	}

	const handleOpenSearch = () => {
		onOpen()
		navigate(`/${userLocale}/search`)
	}

	const handleCloseSearch = () => {
		onClose()
		navigate(`/${userLocale}`)
	}

	const handleSearchByHeight = (close: boolean) => {
		setEditingBlockHeight(true)
		if (close) {
			onClose()
		}
		setTimeout(() => {
			handleFocusBlockHeightInput()
		}, 50)
		navigate(`/${userLocale}`)
	}

	const handleSearchByDate = () => {
		onClose()
		onOpenDatePickerModal()
		navigate(`/${userLocale}/date`)
	}

	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	useEffect(() => {
		if (location.pathname.includes('/search')) {
			onOpen()
		}
	}, [location])

	return (
		<>
			{!confMode && (
				<TCSearchButton onClick={handleOpenSearch} />
			)}

			<Modal
				key="search"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="sm"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
				>
					<ModalBody mt={4}>
						<Flex direction="column" gap={4}>
							<Button
								onClick={() => handleSearchByHeight(true)}
								variant="outline"
								colorScheme="black"
								borderWidth={2}
								fontSize="md"
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<SearchIcon boxSize={5} mr={2} /> <FormattedMessage id="search.modal_button_label.by_block" />
							</Button>

							<Button
								onClick={handleSearchByDate}
								variant="outline"
								colorScheme="black"
								borderWidth={2}
								fontSize="md"
								textTransform="uppercase"
								mb={2}
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<SearchIcon boxSize={5} mr={2} /> <FormattedMessage id="search.modal_button_label.by_date" />
							</Button>

							<Flex
								w="100%"
								align="center"
								direction="column"
							>
								<Text
									textTransform="uppercase"
									fontWeight="semibold"
									mb={1}
								>
									Jump to Epoch Start
								</Text>
								<EpochButtons handleChangeBlock={handleChangeBlock} />
							</Flex>
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Flex
							w="100%"
							justify="flex-end"
							align="flex-end"
						>
							<Button
								onClick={handleCloseSearch}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								borderWidth={2}
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<CloseButtonLabel />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
