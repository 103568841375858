import { atom } from 'recoil'

type WS = WebSocket | null
type date = Date | null

export const connectionState = atom({
	key: 'connection',
	default: null as WS,
})

export const isConnectedState = atom({
	key: 'isConnected',
	default: false,
})

export const appRenderedState = atom({
	key: 'appRendered',
	default: false,
})

export const appInitializedState = atom({
	key: 'appInitialized',
	default: false,
})

export const appInitStartTimeState = atom({
	key: 'appInitStartTime',
	default: null as date,
})

export const staticModeState = atom({
	key: 'staticMode',
	default: false,
})

export const scrubbingState = atom({
	key: 'scrubbing',
	default: false,
})

export const pastDateSearchingState = atom({
	key: 'pastDateSearching',
	default: false,
})

export const confModeState = atom({
	key: 'confMode',
	default: false,
})

export const confImgState = atom({
	key: 'confImg',
	default: '',
})

export const confImgTopState = atom({
	key: 'confImgTop',
	default: 0,
})

export const confBgImgState = atom({
	key: 'confBgImg',
	default: '',
})

export const confBgImgBlendState = atom({
	key: 'confBgImgBlend',
	default: '',
})

export const confUpperMessageState = atom({
	key: 'confUpperMessage',
	default: '',
})

export const confLowerMessageState = atom({
	key: 'confLowerMessage',
	default: '',
})

export const halvingVideoPlayingState = atom({
	key: 'halvingVideoPlaying',
	default: false,
})
