import {
	Flex,
	Text,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useRecoilValue } from 'recoil'
import {
	economyFeeState,
	fastestFeeState,
	mempoolMinFeeState,
	mempoolUsageState,
	maxMempoolState,
	circlesBreakpointState,
} from '../../../../state'
import { STYLE_TRANSITION_ALL, feeThreshold } from '../../../../constants'
import { FeeSection } from '../Lower.components'
import { useAppSelector } from '../../../../hooks'
import { getPurgeMinFee, getBreakpointValue } from '../../../../utils'
import { Label } from '../../../shared'

export const FeesSection = () => {
	const intl = useIntl()
	const { feesRTL } = useAppSelector(({ settings }) => settings)
	const [lightgreen80, lightgreen60, green100] = useToken('colors', ['lightgreen80', 'lightgreen60', 'green100'])
	const fastestFee = useRecoilValue(fastestFeeState)
	const economyFee = useRecoilValue(economyFeeState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const mempoolMinFee = useRecoilValue(mempoolMinFeeState)
	const mempoolUsage = useRecoilValue(mempoolUsageState)
	const maxMempool = useRecoilValue(maxMempoolState)

	const border = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.3)')
	const text = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const textDark = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const bg = useColorModeValue('white', 'black')
	const lowFeeTextColor = useColorModeValue(lightgreen80, green100)
	const lowFeeBorderColor = useColorModeValue(lightgreen80, lightgreen60)
	const responsiveSectionLabelSize = getBreakpointValue({
		base: '9px',
		xs: '10px',
		lg: '11px',
		xl: '12px',
		xxxl: '13px',
	}, circlesBreakpoint)

	const purgeBelowFee = getPurgeMinFee(mempoolMinFee)
	const isPurging = (purgeBelowFee > 1.01) && (mempoolUsage > maxMempool) // dont show purging if mempool is not overflowing

	const getFeeSectionStyle = () => {
		let textColor = text,
			borderColor = border

		const lowFees = economyFee > 0 && fastestFee < 20
		const highFees = economyFee > 0 && fastestFee > feeThreshold.superHot

		if (lowFees) {
			textColor = lowFeeTextColor
			borderColor = lowFeeBorderColor
		}
		if (highFees) {
			textColor = 'red100'
			borderColor = 'red.700'
		}

		return {
			textColor,
			borderColor,
		}
	}
	const { borderColor, textColor } = getFeeSectionStyle()
	const height = 4
	const borderTopStyles = {
		content: '""',
		position: 'absolute',
		width: '100%',
		height: '1px',
		top: '8px',
		bg: borderColor,
		zIndex: 0,
	}

	return (
		<Flex
			className="fees-section"
			pos="relative"
			zIndex={2}
			direction="column"
			w="100%"
			mb={6}
		>
			<Flex
				className="fees-wrap"
				flexShrink={0}
				pos="relative"
				w="100%"
				mt={3}
				transition={STYLE_TRANSITION_ALL}
			>
				{feesRTL && (
					<>
						<FeeSection
							title={intl.formatMessage({ id: 'fees.high' })}
							fee={fastestFee}
							borderColor={borderColor}
							isPurging={isPurging}
						/>
						<FeeSection
							title={intl.formatMessage({ id: 'fees.anytime' })}
							fee={economyFee}
							borderColor={borderColor}
							isPurging={isPurging}
						/>
						{isPurging && (
							<FeeSection
								title={intl.formatMessage({ id: 'fees.purge' })}
								borderColor={borderColor}
								purgeBelowFee={purgeBelowFee}
								isPurging={isPurging}
							/>
						)}
					</>
				)}

				{!feesRTL &&
					<>
						{isPurging && (
							<FeeSection
								title={intl.formatMessage({ id: 'fees.purge' })}
								borderColor={borderColor}
								purgeBelowFee={purgeBelowFee}
								isPurging={isPurging}
							/>
						)}
						<FeeSection
							title={intl.formatMessage({ id: 'fees.anytime' })}
							fee={economyFee}
							borderColor={borderColor}
							isPurging={isPurging}
						/>
						<FeeSection
							title={intl.formatMessage({ id: 'fees.high' })}
							fee={fastestFee}
							borderColor={borderColor}
							isPurging={isPurging}
						/>
					</>
				}
			</Flex>

			<Flex
				direction="column"
				pos="absolute"
				w="100%"
				h={height}
				flexShrink={0}
				top={0}
				transition={STYLE_TRANSITION_ALL}
				_after={borderTopStyles}
			>
				<Flex
					pos="relative"
					h={height}
					px={5}
					top={0}
					left={0}
					right={0}
					m="auto"
					justify="center"
					align="center"
					bg={bg}
					borderRadius="30px"
					borderWidth={1}
					borderColor={borderColor}
					zIndex={1}
					transition={STYLE_TRANSITION_ALL}
				>
					<Text
						display="flex"
						align="center"
						fontSize={responsiveSectionLabelSize}
						lineHeight="14px"
						fontWeight="bold"
						color={textColor}
						textTransform="uppercase"
						transition={STYLE_TRANSITION_ALL}
					>
						<FormattedMessage id="fees.section_title" />
					</Text>
				</Flex>
			</Flex>

			<Flex
				w="100%"
				justify="center"
				mt={2}
			>
				<Label color={textDark} fontWeight="semibold">
					<FormattedMessage id="fees.all_rates" /> sats<span style={{ textTransform: 'lowercase' }}>/v</span>B
				</Label>
			</Flex>
		</Flex>
	)
}
