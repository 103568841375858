import type { MouseEventHandler } from 'react'
import {
	Box,
	Button,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { getBreakpointValue } from '../../../utils'
import { menuLabelFontSize } from '../../../constants'

interface DonateButtonProps {
	onClick: MouseEventHandler<HTMLDivElement>
	isHeader: boolean
}

export const TCDonateButton = ({ onClick, isHeader, ...rest }: DonateButtonProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const responsiveButtonLabelSize = getBreakpointValue(menuLabelFontSize, circlesBreakpoint)
	const buttonHeight = getBreakpointValue({ base: '30px', lg: '38px' }, circlesBreakpoint)
	const buttonLineHeight = getBreakpointValue({ base: '26px', lg: '36px' }, circlesBreakpoint)
	const borderWidth = isHeader ? 0 : 2
	const [lightorange80] = useToken('colors', ['lightorange80'])
	const orange = useColorModeValue(lightorange80, 'orange100')
	const darkOrange = useColorModeValue('orange100', lightorange80)

	return (
		<Box
			className="tc-donate-button-wrap"
			onClick={onClick}
			cursor="pointer"
			role="group"
			pos="relative"
			zIndex={1015}
			{...rest}
		>
			<Button
				w="100%"
				size="sm"
				variant="outline"
				bg="transparent"
				fontSize={responsiveButtonLabelSize}
				fontWeight="bold"
				height={buttonHeight}
				lineHeight={buttonLineHeight}
				textTransform="uppercase"
				py="0"
				px="12px"
				color={orange}
				borderColor="transparent"
				borderWidth={borderWidth}
				borderRadius="md"
				_hover={{
					color: darkOrange,
				}}
				_active={{
					color: darkOrange,
				}}
				_groupHover={{
					color: darkOrange,
				}}
			>
				<FormattedMessage id="info.contact.donate" />
			</Button>
		</Box>
	)
}