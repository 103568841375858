import { useState, type PropsWithChildren } from 'react'
import {
	Flex,
	Input,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Button,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Image,
	useColorModeValue,
} from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'

import { useAppSelector, useAppDispatch } from '../../../hooks'
import {
	confModeState,
	confImgState,
	confImgTopState,
	confBgImgState,
	confBgImgBlendState,
	confUpperMessageState,
	confLowerMessageState,
} from '../../../state'
import { BITCOIN_ORANGE } from '../../../constants'
import { CloseButtonLabel, Label, ModalOverlay } from '../../shared'
import { SponsorSelector } from './SponsorSelector'
import { setSlideshowTiming } from './sponsorsReducer'

interface TCConfModeProps {
	isOpenConfMode: boolean
	onCloseConfMode: () => void
}

export const TCConfModeModal = ({ isOpenConfMode, onCloseConfMode }: TCConfModeProps) => {
	const dispatch = useAppDispatch()
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const { slideshowTiming } = useAppSelector(({ confModeSponsors }) => confModeSponsors)
	const navigate = useNavigate()
	const [confMode, setConfMode] = useRecoilState(confModeState)
	const [confImg, setConfImg] = useRecoilState(confImgState)
	const [confImgTop, setConfImgTop] = useRecoilState(confImgTopState)
	const [confBgImg, setConfBgImg] = useRecoilState(confBgImgState)
	const [confBgImgBlend, setConfBgImgBlend] = useRecoilState(confBgImgBlendState)
	const [confUpperMessage, setConfUpperMessage] = useRecoilState(confUpperMessageState)
	const [confLowerMessage, setConfLowerMessage] = useRecoilState(confLowerMessageState)

	const [upperMessage, setUpperMessage] = useState(confUpperMessage)
	const [img, setImg] = useState(confImg)
	const [imgTop, setImgTop] = useState(confImgTop)
	const [bgImg, setBgImg] = useState(confBgImg)
	const [bgImgBlend, setBgImgBlend] = useState(confBgImgBlend)
	const [timing, setTiming] = useState(slideshowTiming)
	const [lowerMessage, setLowerMessage] = useState(confLowerMessage)

	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')

	const buttonLabel = confMode
		? 'CONF MODE OFF'
		: 'CONF MODE ON'

	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleUpperMessageChange = (event: any) => {
		setUpperMessage(event.target.value)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleConfImgChange = (event: any) => {
		setImg(event.target.value)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleImgTopChange = (event: any) => {
		setImgTop(event.target.value)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleConfBgImgChange = (event: any) => {
		setBgImg(event.target.value)
		console.log('BG:', event.target.value)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleBgImgBlendChange = (event: any) => {
		setBgImgBlend(event.target.value)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleLowerMessageChange = (event: any) => {
		setLowerMessage(event.target.value)
	}

	const handleConfModeOff = () => {
		const newUrl = `/${userLocale}`
		setConfMode(false)
		setConfUpperMessage('')
		setConfImg('')
		setConfImgTop(0)
		setConfBgImg('')
		setConfBgImgBlend('')
		setConfLowerMessage('')
		onCloseConfMode()
		navigate(newUrl)
	}

	const handleConfModeOn = () => {
		const upper_message = upperMessage ? `&upper_message=${encodeURIComponent(upperMessage)}` : ''
		const img_url = img ? `img_url=${encodeURIComponent(img)}` : ''
		const img_top = `&img_top=${imgTop}`
		const bg_img_url = bgImg ? `&bg_img_url=${encodeURIComponent(bgImg)}` : ''
		const bg_img_blend = bgImgBlend ? `&bg_img_blend=${encodeURIComponent(bgImgBlend)}` : ''
		const lower_message = lowerMessage ? `&lower_message=${encodeURIComponent(lowerMessage)}` : ''
		const newUrl = `/${userLocale}/conference?${img_url}${img_top}${bg_img_url}${bg_img_blend}${upper_message}${lower_message}`
		setConfMode(true)
		setConfUpperMessage(upperMessage)
		setConfImg(img)
		setConfImgTop(imgTop)
		setConfBgImg(bgImg)
		setConfBgImgBlend(bgImgBlend)
		setConfLowerMessage(lowerMessage)
		dispatch(setSlideshowTiming(timing))
		onCloseConfMode()
		navigate(newUrl)
	}

	const handleConfModeButtonClick = confMode
		? handleConfModeOff
		: handleConfModeOn

	const SettingsTab = ({ children }: PropsWithChildren) => {
		return (
			<Tab
				fontSize="11px"
				textTransform="uppercase"
				fontWeight="bold"
				px="10px"
				_active={{
					backgroundColor: 'transparent',
				}}
				_hover={{
					color: BITCOIN_ORANGE,
				}}
			>
				{children}
			</Tab>
		)
	}

	const HR = () => <hr style={{ opacity: 0.2, marginTop: '10px', marginBottom: '10px' }} />

	return (
		<Modal
			key="confMode"
			isOpen={isOpenConfMode}
			onClose={onCloseConfMode}
			isCentered
			motionPreset="slideInBottom"
			size="full"
		>
			<ModalOverlay />

			<ModalContent
				bg={bg}
				borderWidth={1}
				borderColor={color}
				color={color}
				borderRadius="lg"
				zIndex={3334}
			>
				<ModalBody
					mt={4}
				>
					<Tabs colorScheme="orange">
						<TabList mb={2}>
							<SettingsTab>Messaging & Image</SettingsTab>
							<SettingsTab>Sponsors</SettingsTab>
						</TabList>

						<TabPanels>
							<TabPanel>
								<Flex direction="column" gap={4}>
									<Flex direction="column">
										<Label mb={1}>
											<b>Upper message</b> <span style={{ textTransform: 'none', marginLeft: '3px' }}>(Displays above Block Height)</span>
										</Label>
										<Input
											value={upperMessage}
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											onChange={(x: any) => handleUpperMessageChange(x)}
											borderColor={colorAlt}
										/>
									</Flex>

									<Flex direction="column">
										<Label mb={1}>
											<b>Lower message</b> <span style={{ textTransform: 'none', marginLeft: '3px' }}>(Displays below the interface)</span>
										</Label>
										<Input
											value={lowerMessage}
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											onChange={(x: any) => handleLowerMessageChange(x)}
											borderColor={colorAlt}
										/>
									</Flex>

									<HR />

									<Flex direction="column">
										<Label mb={1}>
											<b>Image URL</b> <span style={{ textTransform: 'none', marginLeft: '3px' }}>(Displays below Block Height)</span>
										</Label>

										<Input
											value={img}
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											onChange={(x: any) => handleConfImgChange(x)}
											borderColor={colorAlt}
											mb={4}
										/>

										<Label mb={1}>
											<b>Top</b> <span style={{ textTransform: 'none', marginLeft: '3px' }}>(Use negative or positive value to adjust position relative to the Block Height)</span>
										</Label>

										<Flex align="center" gap={2}>
											<Input
												type="number"
												value={imgTop}
												onChange={handleImgTopChange}
												borderColor={colorAlt}
												w="80px"
												max={100}
												min={-100}
											/><b>%</b>
										</Flex>
									</Flex>

									{img && (
										<Flex direction="column" align="flex-start">
											<Label mb={1}>
												<b>Image Preview:</b>
											</Label>

											<Flex justify="center">
												<Image boxSize="200px" objectFit="cover" src={img} />
											</Flex>
										</Flex>
									)}

									<Flex direction="column">
										<Label mb={1}>
											<b>Background Img URL</b> <span style={{ textTransform: 'none', marginLeft: '3px' }}>(Will be displayed full screen behind the interface)</span>
										</Label>
										
										<Input
											value={bgImg}
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											onChange={(x: any) => handleConfBgImgChange(x)}
											borderColor={colorAlt}
											mb={4}
										/>
									</Flex>

									<Label mb={1}>
										<b>Background Img Blend Mode</b> <span style={{ textTransform: 'none', marginLeft: '3px' }}>(Possible values: normal, multiply, screen, overlay, color-dodge, color-burn, hard-light, difference, exclusion, hue, saturation, darken, lighten, color, luminosity)</span>
									</Label>

									<Flex align="center" gap={2}>
										<Input
											value={bgImgBlend}
											onChange={handleBgImgBlendChange}
											borderColor={colorAlt}
											w="180px"
										/>
									</Flex>
								</Flex>
							</TabPanel>

							<TabPanel>
								<SponsorSelector timing={timing} setTiming={setTiming} />
							</TabPanel>
						</TabPanels>
					</Tabs>


				</ModalBody>

				<ModalFooter>
					<Flex
						w="100%"
						justify="flex-end"
						align="flex-end"
						gap={4}
					>
						<Button
							onClick={onCloseConfMode}
							variant="outline"
							colorScheme="black"
							fontSize="sm"
							borderWidth={2}
							textTransform="uppercase"
							_active={interactionStyles}
							_hover={interactionStyles}
						>
							<CloseButtonLabel />
						</Button>

						{confMode && (
							<Button
								onClick={handleConfModeOn}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								borderWidth={2}
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								Update
							</Button>
						)}

						<Button
							onClick={handleConfModeButtonClick}
							variant="outline"
							colorScheme="black"
							fontSize="sm"
							borderWidth={2}
							textTransform="uppercase"
							_active={interactionStyles}
							_hover={interactionStyles}
						>
							{buttonLabel}
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
