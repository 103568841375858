import {
	Box,
	Text,
	Spinner,
	useColorModeValue,
} from '@chakra-ui/react'
import format from 'date-fns/format'
import { useRecoilValue } from 'recoil'
import { FormattedMessage } from 'react-intl'

import {
	estimatedRetargetDateState,
	difficultyChangeState,
	circlesBreakpointState,
	remainingBlocksState,
} from '../../../../state'
import { useAppSelector } from '../../../../hooks'
import { dateLocales } from '../../../../lang/dateFnsLocales'
import { Label } from '../../../shared'
import {
	positiveInt,
	getDateFormat,
	getBreakpointValue,
} from '../../../../utils'
import { primaryDataFontSize } from './dataComponents.constants'
import { DIFFICULTY_ADJUSTMENT_BLOCKS, ONE_DAY_BLOCKS } from '../../../../constants'

const NextDiffAdj = () => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const remainingBlocks = useRecoilValue(remainingBlocksState)
	const difficultyChange = useRecoilValue(difficultyChangeState)

	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const color = useColorModeValue('black', 'white')

	const nextChangeEst = difficultyChange ? parseFloat(difficultyChange.toFixed(2)) : 0
	const shouldWait = remainingBlocks > (DIFFICULTY_ADJUSTMENT_BLOCKS - ONE_DAY_BLOCKS)
	const opacity = shouldWait ? 0 : 1
	
	return (
		<Box pos="relative">
			<Text
				lineHeight={fontSize}
				my={1}
				display="flex"
				color={color}
				fontSize={fontSize}
				fontWeight="semibold"
				opacity={opacity}
				gap="2px"
			>
				{nextChangeEst > 0 && (
					<span>&uarr;</span>
				)}
				{nextChangeEst < 0 && (
					<span>&darr;</span>
				)}
				{nextChangeEst !== 0 && `${positiveInt(nextChangeEst)}%`}
				{nextChangeEst === 0 && '-'}
				
			</Text>

			{shouldWait && (
				<Spinner
					pos="absolute"
					top={0}
					left={0}
					right={0}
					bottom={0}
					m="auto"
					size="sm"
					speed="1s"
					color="gray"
				/>
			)}
		</Box>
		
	)
}

const NextDiffEstDate = () => {
	const { dateFormat, userLocale } = useAppSelector(({ settings }) => settings)
	const estimatedRetargetDate = useRecoilValue(estimatedRetargetDateState)

	const labelColor = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255, 0.7)')

	const estDate = estimatedRetargetDate ? new Date(estimatedRetargetDate) : null
	const formattedEstDate = estDate
		? format(estDate, getDateFormat(dateFormat), { locale: dateLocales[userLocale] })
		: ''

	return (
		<>
			{estDate && (
				<Label color={labelColor} fontWeight="semibold">
					~ {formattedEstDate}
				</Label>
			)}
		</>
	)
}

export const DataNextDiffAdj = () => {
	return (
		<>
			<Label>
				<FormattedMessage id="data.next_diff_adj.title" />
			</Label>

			<NextDiffAdj />

			<NextDiffEstDate />
		</>
	)
}
