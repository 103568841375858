import {
	Flex,
	Text,
	Link,
	Button,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	useDisclosure,
	useColorModeValue,
	UnorderedList,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState, nodesState } from '../../../../state'
import {
	Label,
	ModalOverlay,
	IconLi,
} from '../../../shared'
import { BITCOIN_ORANGE } from '../../../../constants'
import { primaryDataFontSize } from './dataComponents.constants'
import { getBreakpointValue } from '../../../../utils'

export const DataReachableNodes = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const nodes = useRecoilValue(nodesState)
	const color = useColorModeValue('black', 'white')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const nodesColor = nodes === 0 ? 'red100' : color
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const orange = useColorModeValue('lightorange80', 'orange100')
	const orangeHover = useColorModeValue('orange100', 'darkorange80')
	const bg = useColorModeValue('white', 'black')

	return (
		<>
			<Flex
				direction="column"
				align="center"
			>
				<Label>
					<FormattedMessage id="data.nodes.title" />
				</Label>

				<Text
					color={nodesColor}
					fontSize={fontSize}
					fontWeight="bold"
					lineHeight="none"
					whiteSpace="nowrap"
					display="flex"
					align="center"
					gap="3px"
					my={1}
				>
					{nodes}
				</Text>

				<Label
					fontWeight="bold"
					color={colorAlt}
					mb={1}
				>
					<FormattedMessage id="data.nodes.total_reachable" />
				</Label>

				<Link
					onClick={onOpen}
					role="group"
					display="flex"
					alignItems="center"
					mt={1}
					gap={1}
					color={orange}
					isExternal
					_hover={{
						textDecoration: 'none',
					}}
				>
					<Label
						fontWeight="bold"
						color={orange}
						_groupHover={{
							color: orangeHover,
						}}
					>
						<FormattedMessage id="data.nodes.run_a_node" />
					</Label>
					
					<ExternalLinkIcon
						boxSize={{ base: 3, md: 4 }}
						color={orange}
						_groupHover={{
							color: orangeHover
						}}
					/>
				</Link>
			</Flex>

			<Modal
				key="nodesModal"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="md"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
				>
					<ModalHeader pb={0}>
						<Flex align="center" gap="1">
							<ExternalLinkIcon color={colorAlt2} />
							<Text
								color={colorAlt}
								fontSize="xs"
								textTransform="uppercase"
							>
								<FormattedMessage id="data.nodes.run_a_node" />
							</Text>
						</Flex>
					</ModalHeader>

					<ModalBody py={10}>
						<Flex direction="column" align="center" gap={10}>
							<UnorderedList styleType="none" ml={0}>
								<IconLi
									imageSrc="/images/bitcoin-logo.png"
									link="https://bitcoincore.org/"
									title="Bitcoin Core"
									description="An open source project which maintains and releases Bitcoin client software called “Bitcoin Core”."
								/>
								<IconLi
									imageSrc="/images/knots-logo.jpeg"
									link="https://bitcoinknots.org/"
									title="Bitcoin Knots"
									description="Bitcoin Knots is a fully verifying implementation of Bitcoin."
								/>
								<IconLi
									imageSrc="/images/lopp.jpeg"
									link="https://www.lopp.net/bitcoin-information/full-node.html"
									title="Lopp.net Node Resources"
									description="Further education about what a node is, how to run a node, and many node implementations and tools."
								/>
							</UnorderedList>
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Flex
							w="100%"
							justify="flex-end"
							align="center"
							gap={8}
						>
							<Button
								onClick={onClose}
								flexShrink={0}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								_active={{
									borderColor: BITCOIN_ORANGE,
									color: BITCOIN_ORANGE,
								}}
								_hover={{
									borderColor: BITCOIN_ORANGE,
									color: BITCOIN_ORANGE,
								}}
							>
								<FormattedMessage id="shared.close_button_label" />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
