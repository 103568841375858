import {
	useState,
	useEffect,
} from 'react'
import {
	Flex,
	Box,
	Text,
	chakra,
	shouldForwardProp,
	useColorModeValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../hooks'
import {
	blockState,
	scrubBlockState,
	staticModeState,
	circlesBreakpointState,
	showBlockRippleState,
	confModeState,
	confBgImgState,
} from '../../../state'
import {
	getBitcoinEpoch,
	getMinerSubsidy,
	getSupplyIssued,
	getBreakpointValue,
	getBlocksToHalving,
	abbreviateSupply,
	roundDown,
} from '../../../utils'
import { Label, BitcoinSymbolEntity } from '../../shared'
import {
	blurInAnimation,
	romanNumerals,
} from '../../../constants'
import {
	cornersDataFontSize,
	secondaryAltDataFontSize,
} from '../../features/calendar/data-components/dataComponents.constants'

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const CornerNW = () => {
	// @ts-ignore
	const { reducedMotion } = useAppSelector(({ settings }) => settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const secondaryFontSize = getBreakpointValue(secondaryAltDataFontSize, circlesBreakpoint)
	const cornersFontSize = getBreakpointValue(cornersDataFontSize, circlesBreakpoint)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const blockHeight = block.height
	const halvingBlocks = getBlocksToHalving(blockHeight)
	const [circleAnimationDuration, setCircleAnimationDuration] = useState(0)
	const scrubBlockHeight = scrubBlock.height
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const confMode = useRecoilValue(confModeState)
	const confBgImg = useRecoilValue(confBgImgState)
	const isConfBackground = confMode && confBgImg

	const color1 = '#ffffff'
	const color2 = '#f7931a'
	const pulseColor = {
		color: [
			color1,
			color1,
			color1,
			color2,
			color1,
		]
	}
	const pulseTransition = {
		duration: circleAnimationDuration,
		ease: 'easeInOut',
		repeat: Infinity,
		repeatType: 'loop',
	}

	const transitionBlocks = 12000
	const shouldAnimate = halvingBlocks <= transitionBlocks
	const shouldPulse = !showBlockRipple
		&& !reducedMotion
		&& shouldAnimate

	// EPOCH
	const epoch = blockHeight ? getBitcoinEpoch(blockHeight) : ''
	const epochRomanNumeral = epoch ? romanNumerals[epoch - 1] : ''
	const scrubEpoch = getBitcoinEpoch(scrubBlockHeight)
	const scrubEpochRomanNumeral = romanNumerals[scrubEpoch - 1]
	const epochSize = getBreakpointValue({
		base: '34px',
		xxs: '40px',
		xs: '51px',
		sm: '62px',
		md: '76px',
		lg: '84px',
		xl: '90px',
		xxl: '100px',
		xxxl: '108px',
		jumbo: '136px',
	}, circlesBreakpoint)
	const epochMt = getBreakpointValue({
		base: '-1px',
		xs: '-3px',
		sm: '-5px',
		md: '-7px',
		xxxl: '-8px',
		jumbo: '-12px',
	}, circlesBreakpoint)

	// SUBSIDY
	const subsidy = epoch ? getMinerSubsidy(epoch) : ''
	const scrubSubsidy = getMinerSubsidy(scrubEpoch)

	// SUPPLY
	const supply = staticMode
		? getSupplyIssued(scrubBlockHeight)
		: getSupplyIssued(blockHeight)
	const supplyIssued = supply?.supplyIssued
		? `${abbreviateSupply(supply?.supplyIssued)}`
		: 0
	const supplyPercent = supply?.supplyIssuedPercent
		? roundDown((supply?.supplyIssuedPercent * 100), 2)
		: 0

	useEffect(() => {
		const durationFloor = 0.1
		const newDuration = Number((((halvingBlocks / transitionBlocks) + durationFloor) * 2).toFixed(2))

		if (shouldAnimate) {
			return setCircleAnimationDuration(newDuration)
		}
		setCircleAnimationDuration(newDuration)
	}, [
		shouldAnimate,
		halvingBlocks,
		transitionBlocks
	])

	return (
		<Flex
			className="tc-corner-nw"
			pos="absolute"
			zIndex={isConfBackground ? undefined : 1200}
			top={0}
			left={0}
			direction="column"
			gap={1}
			animation={blurInAnimation}
			color={color}
		>
			{isConfBackground && (
				<Box
					pos="absolute"
					w="220px"
					top="-20px"
					left="-20px"
					bottom="-20px"
					bg="rgba(0,0,0,0.6)"
					filter="blur(20px)"
					zIndex={-1}
				/>
			)}

			<Flex gap={4}>
				<Box minW={epochSize}>
					<Label color={color}>
						<FormattedMessage id="nw_corner.epoch" />
					</Label>

					<MotionBox
						animate={shouldPulse ? pulseColor : undefined}
						// @ts-ignore
						transition={shouldPulse ? pulseTransition : undefined}
					>
						<Text
							color="currentColor"
							textStyle="roman"
							fontSize={epochSize}
							lineHeight="none"
							letterSpacing="tighter"
							mt={epochMt}
						>
							{staticMode && scrubEpochRomanNumeral}
							{!staticMode && epochRomanNumeral}
						</Text>
					</MotionBox>
				</Box>

				<Box>
					<Label color={color}>
						<FormattedMessage id="nw_corner.subsidy" />
					</Label>

					<MotionBox
						animate={shouldPulse ? pulseColor : undefined}
						// @ts-ignore
						transition={shouldPulse ? pulseTransition : undefined}
					>
						<Text
							color="currentColor"
							fontSize={cornersFontSize}
							lineHeight="none"
							my={1}
						>
							<span style={{ color: colorAlt }}><BitcoinSymbolEntity /></span>
							<b>
								{staticMode && scrubSubsidy}
								{!staticMode && subsidy}
							</b>
						</Text>
					</MotionBox>
					
					<Label
						color={colorAlt}
						fontWeight="semibold"
					>
						<FormattedMessage id="nw_corner.per_block" />
					</Label>
				</Box>
			</Flex>

			<Flex direction="column">
				<Label color={color}>
					<FormattedMessage id="nw_corner.supply" />
				</Label>

				<Text
					fontSize={cornersFontSize}
					lineHeight="none"
					my={1}
				>
					<span style={{ color: colorAlt }}><BitcoinSymbolEntity /></span>
					<b>{supplyIssued}</b>
				</Text>

				<Text
					fontSize={secondaryFontSize}
					lineHeight="none"
					mb="2px"
				>
					<span style={{ color: color, fontWeight: 'bold' }}>{supplyPercent}%</span>
				</Text>
				
				<Label
					color="whiteAlpha.700"
					fontWeight="semibold"
				>
					<span style={{ color: colorAlt, fontWeight: 'semibold' }}><FormattedMessage id={'nw_corner.of'} /> <span>21M</span></span>
				</Label>
			</Flex>
		</Flex>
	)
}
